import { useEffect, useState } from "react";
import ChangePassword from "../../components/Authentication/ChangePassword";
function Profile() {


  return (
    <div className="page-inner">
      {/* Main Wrapper */}
      <div id="main-wrapper">
        <div className="pageheader pd-t-25 pd-b-35">
          <div className="pd-t-5 pd-b-5">
            <h1 className="pd-0 mg-0 tx-24 text-overflow">Profile</h1>
          </div>
        </div>

        <div className="row row-xs clearfix">
          <div className="col-md-12 col-lg-12">
            <div className="card mg-b-20">
              <div className="card-header">
                {/* <h4 className="card-header-title">Users List</h4> */}
                <div className="card-header-btn">
                  <a
                    href
                    data-toggle="collapse"
                    className="btn card-collapse"
                    data-target="#collapse1"
                    aria-expanded="true"
                  >
                    <i className="ion-ios-arrow-down"></i>
                  </a>
                </div>

                <div className="btn-group"></div>
              </div>
              <div className="card-body collapse show" id="collapse1">
                <ChangePassword />
                </div>
            
            </div>
          </div>
        </div>
      </div>
      {/*/ Main Wrapper End */}
    </div>
  );
}

export default Profile;
