import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { toast } from 'react-toastify';
import { FieldSchema } from '../../schema/FieldSchema';
import TextError from '../../helpers/FormikHelper/TextError';
import userServices from '../../services/user.services';
import { BASE_URL } from '../../services/config';

const HostForm = ({ toggle, isAddMode, selectedRow, getData }) => {
    const [file, setFile] = useState(isAddMode === 'Add' ? '' : selectedRow?.profilePhoto && `${BASE_URL}/${selectedRow?.profilePhoto}`);
  
    // function handleChange(e) {
    //     if (e.target.files.length > 0) {
    //         setFile(URL.createObjectURL(e.target.files[0]));
    //     } else {
    //         setFile('')
    //     }
    // }

    const initialValues = {
        name: '',
        stayType: '',
        description: '',
        code: '',
        email: ''
        // default: false,
    }

    const savedValues = {
        stayType: selectedRow?.stayType,
        description: selectedRow?.description,
        code: selectedRow?.code,
        email: selectedRow?.email,
        name: selectedRow?.name
        // default: selectedRow?.default,
    }

    const onSubmit = async (values) => {

        if (isAddMode === 'Add') {
            let result = await userServices.addStayType(values);
            if (result.data.status === 'success') {
                setFile('')
                toggle()
                getData()
                toast.success('Data Added successfully', { toastId: 'registration_success', autoClose: 3000 });
            } else {
                toast.error(result.data.message, { toastId: 'registration_fail', autoClose: 3000 });
            }
        } else {
            values.id = selectedRow?._id
            const result = await userServices.updateStayType(values);
            if (result.data.status === 'success') {
                setFile('')
                toggle()
                getData()
                toast.success('Data Updated successfully', { toastId: 'update_success', autoClose: 3000 });
            } else {
                toast.error(result.data.message, { toastId: 'update_fail', autoClose: 3000 });

            }
        }
    }

    return (
        <React.Fragment>
            <Formik
                initialValues={isAddMode === 'Add' ? initialValues : savedValues}
                validationSchema={FieldSchema(`${isAddMode === 'Add' ? 'ADD_STAY' : 'ADD_STAY'}`)}
                onSubmit={onSubmit}
                enableReinitialize
            >
                {({ setFieldValue, values }) => (<Form>
                    <ModalHeader toggle={toggle} className='border-0 pb-0'>
                        <div className="card-title d-flex align-items-center mb-0">
                            <h5 className="mb-0 text-primary">{isAddMode === 'Add' ? 'Add Stay Types' : 'Host Detail'}</h5>
                        </div>
                    </ModalHeader>
                    <ModalBody className="">
                        <div className="row" >

                        <div className="col-12 mb-3">
                                {/* <label htmlFor="file" className="form-label">
                                    image/icon
                                </label> */}
                                <h6>image/icon</h6>

                                {/* <input id="file" name="file" type="file" onChange={(event) => {
                                    setFieldValue("file", event.currentTarget.files[0]);
                                    handleChange(event)
                                }} className="form-control" /> */}
                                {file === undefined || file === '' || file === null ? '' : <img src={file}
                                    className="img-thumbnail mt-2" 
                                    height={150}
                                    width={150}
                                    />}
                            </div>

                            <div className="col-12 mb-3">
                            
                            </div>
                            <div className="col-12 mb-3">
                                <div className='d-flex align-items-center'>
                                    <h6>Host Name </h6>
                                </div>
                                {/* <label htmlFor="stayType" className="form-label">
                                Staty Type
                                </label> 
                                <ErrorMessage name='stayType' component={TextError} />*/}
                                <Field type="text" className="form-control" id="name" name='name' readonly/>
                            </div>
                            <div className="col-12 mb-3">
                                <div className='d-flex align-items-center'>
                                    <h6>languages </h6>
                                </div>
                                {/* <label htmlFor="stayType" className="form-label">
                                Staty Type
                                </label> 
                                <ErrorMessage name='stayType' component={TextError} />*/}
                                <Field type="text" className="form-control" id="stayType" name='stayType' readonly/>
                            </div>
                            <div className="col-12 mb-3">
                                {/* <label htmlFor="description" className="form-label">
                                    Description
                                </label> */}
                                <h6>alternative phone</h6>
                                <Field type="description" className="form-control" id="description" name='description' readonly/>
                                <ErrorMessage name='description' component={TextError} />
                            </div>

                            <div className="col-12 mb-3">
                                {/* <label htmlFor="code" className="form-label">
                                    Code
                                </label> */}
                                <h6>Address</h6>
                                <Field type="code" className="form-control" id="code" name='code'readonly/>
                                <ErrorMessage name='code' component={TextError} />
                            </div>

                            <div className='col-12'>
                                <h6>Join date</h6>
                                <Field type="code" className="form-control" id="code" name='code'readonly/>
                                <ErrorMessage name='code' component={TextError} />
                            </div>

                            
                        </div>
                    </ModalBody>
                    <ModalFooter className='border-0 pt-0'>
                        <button
                            type="button"
                            className="btn btn-secondary btn-sm"
                            onClick={() => {
                                toggle();
                                setFile('')
                            }}
                        >
                            Close
                        </button>
                        <button type="submit" className="btn btn-primary btn-sm">
                            {isAddMode === "Add" ? `Save` : `Update`}
                        </button>
                    </ModalFooter>
                </Form>
                )}
            </Formik>
        </React.Fragment>
    );
};

export default HostForm;