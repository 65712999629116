import React from 'react';
import { Field, ErrorMessage, useFormikContext } from 'formik';

const FoodSelect = ({ foods }) => {
  const { setFieldValue, values } = useFormikContext();

  const handleSelectChange = (event) => {
    const selectedValues = Array.from(event.target.selectedOptions, (option) => option.value);
    setFieldValue('includedFood', selectedValues); // Correctly update Formik's state with selected values
  };

  return (
    <div className="col-6 mb-3">
      <label htmlFor="includedFood" className="form-label">
        Food
      </label>
      <Field
        as="select"
        className="form-select"
        id="includedFood"
        name="includedFood"
        multiple
        value={values.includedFood} // Bind the value to Formik's state
        onChange={handleSelectChange}
      >
        <option disabled value="">
          Select Food
        </option>
        {foods.map((e, key) => (
          <option key={key} value={e._id}>
            {e.foodName}
          </option>
        ))}
      </Field>
      <ErrorMessage name="includedFood" component="div" className="text-danger" />
    </div>
  );
};

export default FoodSelect;
