import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
// import Error404 from '../pages/Errors/Error404';
import PrivateRoutes from './PrivateRoutes';
import Login from '../pages/Authentication/Login';
import Logout from'../pages/Authentication/Logout'
import Host from '../pages/Host';
import HostDetail from '../pages/HostDetails';
import Properties from '../pages/Properties';
import Profile from '../pages/Authentication/Profile';
import InActiveProperties from '../pages/InActiveProperties';
import Experience from '../pages/Experience';
import UnApprovedExperience from '../pages/UnApprovedExperience';
import Bookings from '../pages/Bookings/Bookings';
import Languages from '../pages/MasterData/Languages';
import Seasons from '../pages/MasterData/Seasons';
import Terrain from '../pages/MasterData/Terrain';
import Amenities from '../pages/MasterData/Amenities';
import StayTypes from '../pages/MasterData/StayTypes';
import ExpTypes from '../pages/MasterData/ExpTypes';
import ExpSubTypes from '../pages/MasterData/ExpSubTypes';
import ExpIncludes from '../pages/MasterData/ExpIncludes';
import Food from '../pages/MasterData/Food';
import TransportationMode from '../pages/MasterData/TransportationMode';
import AddOnType from '../pages/MasterData/AddOnType';
import GuestPackingList from '../pages/MasterData/GuestPackingList';
import Rules from '../pages/MasterData/Rules';
import Policies from '../pages/MasterData/Policies';
import Stays from '../pages/Stays/Stays';
import AddOns from '../pages/AddOns/AddOns';
import Events from '../pages/Events/Events';

const AppRoute = () => {

    return (
        <Router>
            <Routes>
              <Route element={<PrivateRoutes/>}>
                  <Route path='/' element={<Host />} />
                  <Route path='/host-detail/:id' element={<HostDetail /> } />
                  <Route path='/properties' element={<Properties /> } />
                  <Route path='/inactive_properties' element={<InActiveProperties /> } />
                  <Route path='/unapprove-experience' element={<UnApprovedExperience /> } />
                  <Route path='/bookings' element={<Bookings /> } />
                  <Route path='/languages' element={<Languages /> } />
                  <Route path='/seasons' element={<Seasons /> } />
                  <Route path='/terrian' element={<Terrain /> } />
                  <Route path='/amenities' element={<Amenities /> } />
                  <Route path='/stay-types' element={<StayTypes /> } />
                  <Route path='/exp-types' element={<ExpTypes /> } />
                  <Route path='/exp-sub-types' element={<ExpSubTypes /> } />
                  <Route path='/exp-includes' element={<ExpIncludes /> } />
                  <Route path='/food' element={<Food /> } />
                  <Route path='/transportation-mode' element={<TransportationMode /> } />
                  <Route path='/add-on-types' element={<AddOnType /> } />
                  <Route path='/guest-packing-list' element={<GuestPackingList /> } />
                  <Route path='/rules' element={<Rules /> } />
                  <Route path='/policies' element={<Policies /> } />
                  <Route path='/stays' element={<Stays /> } />
                  <Route path='/add-ons' element={<AddOns /> } />
                  <Route path='/events' element={<Events /> } />
                  <Route path='/experience' element={<Experience /> } />

                  {/* <Route path='/active-conveyance' element={<ActiveConveyance /> } />
                  <Route path='/published-conveyance' element={<PublishedConveyance /> } /> */}
                  <Route path='/profile' element={<Profile /> } />
                  <Route path='/logout' element={<Logout />} />
              </Route>
              <Route path='/login' element={<Login />}/>
              {/* <Route path="*" element={<Error404 />} /> */}
            </Routes>
        </Router>
      );
    }
  export default AppRoute;
