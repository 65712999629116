import { useEffect, useState } from "react";
import userServices from "../../services/user.services";
import { Modal} from 'reactstrap';
import AddOnTypeList from "../../components/MasterData/AddOnTypeList";
import AddonTypeForm from "../../components/MasterData/AddonTypeForm";

function AddOnType() {
    const [data, setData] = useState([])
    const [selectedRow, setSelectedRow] = useState();
    const [isAddMode, setIsAddMode] = useState('Add');
    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);
    const getData = async () => {
        const result = await userServices.addOnTypeList()
        if (result.status === 200) {
            setData(result.data.data)
        }
    }

    useEffect(() => {
        getData()
    }, [])
    return (
        <div className="page-inner">
            {/* Main Wrapper */}
            <div id="main-wrapper">
                <div className="pageheader pd-t-25 pd-b-35">
                    <div className="pd-t-5 pd-b-5">
                        <h1 className="pd-0 mg-0 tx-24 text-overflow">ADD ON TYPES</h1>
                    </div>
                </div>

                <div className="row row-xs clearfix">
                    <div className="col-md-12 col-lg-12">
                        <div className="card mg-b-20">
                            <div className="card-header">
                                {/* <h4 className="card-header-title">Users List</h4> */}
                                <div className="card-header-btn">
                                    <a
                                        href
                                        data-toggle="collapse"
                                        className="btn card-collapse"
                                        data-target="#collapse1"
                                        aria-expanded="true"
                                    >
                                        <i className="ion-ios-arrow-down"></i>
                                    </a>


                                </div>

                                <div className="btn-group">

                                    <button
                                        type="button "
                                        className="btn return-btn btn-sm"
                                    onClick={()=>{
                                        toggle();
                                        setIsAddMode('Add')
                                    }}
                                    >
                                        Add
                                    </button>
                                </div>
                            </div>

                            <div className="card-body collapse show" id="collapse1">

                                <AddOnTypeList
                                    data={data}
                                    setSelectedRow={setSelectedRow}
                                    setIsAddMode={setIsAddMode}
                                    setModal={setModal}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*/ Main Wrapper End */}

            <Modal isOpen={modal} toggle={toggle} size="md" centered={true} className="">
              <AddonTypeForm  toggle={toggle} isAddMode={isAddMode} selectedRow={selectedRow}  getData={getData}/>

            </Modal>
        </div>
    );
}

export default AddOnType;
