import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { toast } from 'react-toastify';
import TextError from '../../helpers/FormikHelper/TextError';
import userServices from '../../services/user.services';
import { FieldSchema } from '../../schema/FieldSchema';
import FoodSelect from './FoodSelected';
import FacilitiesSelect from './FacilitiesSelected';

const StayForm = ({ toggle, isAddMode, selectedRow, getData }) => {
    const [stayTypes, setStayTypes] = useState([])
    const [foods, setFoods] = useState([])
    const [facilities, setFacilites] = useState([])

    const initialValues = {
        serviceName: '',
        addonTypeId: '',
        propertyName: '',
        serviceDescription: '',
        price: ''
    }

    const savedValues = {
        propertyName: selectedRow?.propertyName,
        stayTypeId: selectedRow?.stayTypeId,
        stayTypeName: selectedRow?.stayTypeName,
        countAvailable: selectedRow?.countAvailable,
        countCapacity: selectedRow?.countCapacity,
        perPerson: selectedRow?.perPerson,
        priceRegular: selectedRow?.priceRegular,
        includedFood: selectedRow?.includedFood,
        priceWeekend: selectedRow?.priceWeekend,
        includedFacilitiesFree: selectedRow?.includedFacilitiesFree,
        description: selectedRow?.description,
        status: selectedRow?.status,
        stayName: selectedRow?.stayName
    }

    const onSubmit = async (values) => {

        if (isAddMode === 'Add') {
            let result = await userServices.addPolicies(values);
            if (result.data.status === 'success') {

                toggle()
                getData()
                toast.success('Data Added successfully', { toastId: 'registration_success', autoClose: 3000 });
            } else {
                toast.error(result.data.message, { toastId: 'registration_fail', autoClose: 3000 });
            }
        } else {
            values.id = selectedRow?._id
            const result = await userServices.updateStayById(values);
            if (result.data.status === 'success') {

                toggle()
                getData()
                toast.success('Data Updated successfully', { toastId: 'update_success', autoClose: 3000 });
            } else {
                toast.error(result.data.message, { toastId: 'update_fail', autoClose: 3000 });

            }
        }
    }

    useEffect(() => {
        userServices.stayTypeList().then((result) => {
            if (result.data.status === 'success') {
                setStayTypes(result.data.data)
            }
        })

        userServices.foodList().then((result) => {
            if (result.data.status === 'success') {
                setFoods(result.data.data)
            }
        })

        userServices.facilitesList().then((result) => {
            if (result.data.status === 'success') {
                setFacilites(result.data.data)
            }
        })
    }, [])

    return (
        <React.Fragment>
            <Formik
                initialValues={isAddMode === 'Add' ? initialValues : savedValues}
                validationSchema={FieldSchema(`${isAddMode === 'Add' ? 'ADD_STAY_DETAIL' : 'ADD_STAY_DETAIL'}`)}
                onSubmit={onSubmit}
                enableReinitialize
            >
                {({ setFieldValue, values }) => (<Form>
                    <ModalHeader toggle={toggle} className='border-0 pb-0'>
                        <div className="card-title d-flex align-items-center mb-0">
                            <h5 className="mb-0 text-primary">{isAddMode === 'Add' ? 'Add Stay' : 'Update Stay'}</h5>
                        </div>
                    </ModalHeader>
                    <ModalBody className="">
                        <div className="row" >
                            <div className="col-12 mb-3">
                                <label htmlFor="propertyName" className="form-label">
                                    Property Name
                                </label>
                                <Field type="text" className="form-control" id="rule" name='propertyName' disabled />
                                <ErrorMessage name='propertyName' component={TextError} />
                            </div>
                            <div className="col-md-6 mb-3">
                                <label htmlFor="stayTypeId" className="form-label">
                                    Stay Types
                                </label>
                                <Field as='select' className='form-select' id="stayTypeId" name="stayTypeId" >
                                    <option disabled value="">Select Stay Types</option>

                                    {
                                        stayTypes.map((e, key) => {
                                            return <option key={key} value={e._id}>{e.stayType}</option>;
                                        })
                                    }
                                </Field>
                                <ErrorMessage name="stayTypeId" component={TextError} />
                            </div>

                            <div className="col-6 mb-3">
                                <label htmlFor="stayName" className="form-label">
                                    Stay Name
                                </label>
                                <Field type="text" className="form-control" id="rule" name='stayName' />
                                <ErrorMessage name='stayName' component={TextError} />
                            </div>
                            <div className="col-12 mb-3">
                                <label htmlFor="description" className="form-label">
                                    Description
                                </label>
                                <Field type="text" className="form-control" id="rule" name='description' />
                                <ErrorMessage name='description' component={TextError} />
                            </div>
                            <div className="col-6 mb-3">
                                <label htmlFor="countAvailable" className="form-label">
                                    Count
                                </label>
                                <Field type="text" className="form-control" id="rule" name='countAvailable' disabled />
                                <ErrorMessage name='countAvailable' component={TextError} />
                            </div>

                            <div className="col-6 mb-3">
                                <label htmlFor="countCapacity" className="form-label">
                                    Capacity
                                </label>
                                <Field type="text" className="form-control" id="rule" name='countCapacity' />
                                <ErrorMessage name='countCapacity' component={TextError} />
                            </div>
                            <div className="col-6 mb-3">
                                <label htmlFor="priceWeekend" className="form-label">
                                    Price Weekend
                                </label>
                                <Field type="text" className="form-control" id="rule" name='priceWeekend' disabled />
                                <ErrorMessage name='priceWeekend' component={TextError} />
                            </div>
                            <div className="col-6 mb-3">
                                <label htmlFor="priceRegular" className="form-label">
                                    Price Weekdays
                                </label>
                                <Field type="text" className="form-control" id="rule" name='priceRegular' disabled />
                                <ErrorMessage name='priceRegular' component={TextError} />
                            </div>

                            <FoodSelect foods={foods} />
                            <FacilitiesSelect facilities={facilities} />

                            <div className="col-md-6 mb-3">
                                <label htmlFor="status" className="form-label">
                                    Status
                                </label>
                                <Field as='select' className='form-select' id="status" name="status" >
                                    <option  value={0}>INACTIVE</option>
                                    <option  value={1}>ACTIVE</option>

                                  
                                </Field>
                                <ErrorMessage name="status" component={TextError} />
                            </div>

                        </div>
                    </ModalBody>
                    <ModalFooter className='border-0 pt-0'>
                        <button
                            type="button"
                            className="btn btn-secondary btn-sm"
                            onClick={() => {
                                toggle();
                            }}
                        >
                            Close
                        </button>
                        <button type="submit" className="btn btn-primary btn-sm">
                            {isAddMode === "Add" ? `Save` : `Update`}
                        </button>
                    </ModalFooter>
                </Form>
                )}
            </Formik>
        </React.Fragment>
    );
};

export default StayForm;