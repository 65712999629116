import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { toast } from 'react-toastify';
import TextError from '../../helpers/FormikHelper/TextError';
import userServices from '../../services/user.services';
import { FieldSchema } from '../../schema/FieldSchema';

const AddOnsForm = ({ toggle, isAddMode, selectedRow, getData }) => {
    const [addOnTypes, setAddonTypes] = useState([])
    // const [file, setFile] = useState(isAddMode === 'Add' ? '' : `${BASE_URL}/${selectedRow?.adventureImg}`);
    // const [expTypes, setExpTypes] = useState([])
    // function handleChange(e) {
    //     if (e.target.files.length > 0) {
    //         setFile(URL.createObjectURL(e.target.files[0]));
    //     } else {
    //         setFile('')
    //     }
    // }

    const initialValues = {
        serviceName: '',
        addonTypeId: '',
        propertyName: '',
        serviceDescription: '',
        price: ''
    }

    const savedValues = {
        serviceName: selectedRow?.serviceName,
        addonTypeId: selectedRow?.addonTypeId,
        propertyName: selectedRow?.propertyName,
        serviceDescription: selectedRow?.serviceDescription,
        price: selectedRow?.price,
        status: selectedRow?.status
    }

    const onSubmit = async (values) => {

        if (isAddMode === 'Add') {
            let result = await userServices.addPolicies(values);
            if (result.data.status === 'success') {

                toggle()
                getData()
                toast.success('Data Added successfully', { toastId: 'registration_success', autoClose: 3000 });
            } else {
                toast.error(result.data.message, { toastId: 'registration_fail', autoClose: 3000 });
            }
        } else {
            values.id = selectedRow?._id
            const result = await userServices.updateAddOns(values);
            if (result.data.status === 'success') {

                toggle()
                getData()
                toast.success('Data Updated successfully', { toastId: 'update_success', autoClose: 3000 });
            } else {
                toast.error(result.data.message, { toastId: 'update_fail', autoClose: 3000 });

            }
        }
    }

    useEffect(() => {
        userServices.addOnTypeList().then((result) => {
            if (result.data.status === 'success') {
                setAddonTypes(result.data.data)
            }
        })
    }, [])

    return (
        <React.Fragment>
            <Formik
                initialValues={isAddMode === 'Add' ? initialValues : savedValues}
                validationSchema={FieldSchema(`${isAddMode === 'Add' ? 'ADD_ADD_ONS' : 'ADD_ADD_ONS'}`)}
                onSubmit={onSubmit}
                enableReinitialize
            >
                {({ setFieldValue, values }) => (<Form>
                    <ModalHeader toggle={toggle} className='border-0 pb-0'>
                        <div className="card-title d-flex align-items-center mb-0">
                            <h5 className="mb-0 text-primary">{isAddMode === 'Add' ? 'Add Ons' : 'Update Add Ons'}</h5>
                        </div>
                    </ModalHeader>
                    <ModalBody className="">
                        <div className="row" >
                            <div className="col-md-12 mb-3">
                                <label htmlFor="addonTypeId" className="form-label">
                                    Add On Types
                                </label>
                                <Field as='select' className='form-select' id="addonTypeId" name="addonTypeId" >
                                    <option disabled value="">Select Add On Types</option>

                                    {
                                        addOnTypes.map((e, key) => {
                                            return <option key={key} value={e._id}>{e.addonName}</option>;
                                        })
                                    }
                                </Field>
                                <ErrorMessage name="addonTypeId" component={TextError} />
                            </div>
                            <div className="col-12 mb-3">
                                <label htmlFor="serviceName" className="form-label">
                                    Add On Name
                                </label>
                                <Field type="text" className="form-control" id="rule" name='serviceName' disabled/>
                                <ErrorMessage name='serviceName' component={TextError} />
                            </div>
                           
                            <div className="col-12 mb-3">
                                <label htmlFor="propertyName" className="form-label">
                                    Associate Property
                                </label>
                                <Field type="text" className="form-control" id="rule" name='propertyName' disabled/>
                                <ErrorMessage name='propertyName' component={TextError} />
                            </div>
                            <div className="col-12 mb-3">
                                <label htmlFor="serviceDescription" className="form-label">
                                    Add On Description
                                </label>
                                <Field type="text" className="form-control" id="rule" name='serviceDescription' />
                                <ErrorMessage name='serviceDescription' component={TextError} />
                            </div>

                            <div className="col-12 mb-3">
                                <label htmlFor="price" className="form-label">
                                    Price
                                </label>
                                <Field type="text" className="form-control" id="rule" name='price' />
                                <ErrorMessage name='price' component={TextError} />
                            </div>

                            <div className="col-md-6 mb-3">
                                <label htmlFor="status" className="form-label">
                                    Status
                                </label>
                                <Field as='select' className='form-select' id="status" name="status" >
                                    <option  value={0}>Draft</option>
                                    <option  value={1}>Published</option>
                                    <option  value={1}>Closed</option>
                                  
                                </Field>
                                <ErrorMessage name="status" component={TextError} />
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter className='border-0 pt-0'>
                        <button
                            type="button"
                            className="btn btn-secondary btn-sm"
                            onClick={() => {
                                toggle();
                            }}
                        >
                            Close
                        </button>
                        <button type="submit" className="btn btn-primary btn-sm">
                            {isAddMode === "Add" ? `Save` : `Update`}
                        </button>
                    </ModalFooter>
                </Form>
                )}
            </Formik>
        </React.Fragment>
    );
};

export default AddOnsForm;