import { required, yup } from "./CommonValidation";
import * as Yup from 'yup';
export const FieldSchema = (type) => {
    if (type === 'CHANGE_PASSWORD') {
        return yup({
            old_password: required('Old Password is required'),
            new_password: required('New Password is required'),
            new_confirm_password: required('').oneOf([Yup.ref('new_password'), null], 'New Passwords must match')
        })
    } else if (type === 'USER_ADD') {
        return yup({
            name: required('Please enter User name'),
            email: required('Please enter email id').email('Please enter valid email'),
            password: required('Password is required'),
        })
    } else if (type === 'USER_EDIT') {
        return yup({
            name: required('Please enter User name'),
            email: required('Please enter email id').email('Please enter valid email'),
        })
    } else if (type === 'ADD_SERVER') {
        return yup({
            ip: required('Please enter Server IP'),
            server_name: required('Please Enter Server Name')
        })
    } else if (type === 'ADD_LANGUAGE') {
        return yup({
            language: required('Please enter language name'),
            countryCode: required('Please enter country code'),
            code: required('Please enter code'),

        })
    } else if (type === 'ADD_SEASON') {
        return yup({
            seasonName: required('Please enter seasion name'),
            display: required('Please enter country code'),
            color: required('Please enter color code'),
            code: required('Please enter code'),

        })
    } else if (type === 'ADD_TERRIAN') {
        return yup({
            terrainName: required('Please enter terrian name'),
            code: required('Please enter code'),

        })
    } else if (type === 'ADD_AMENITIES') {
        return yup({
            amenitiesName: required('Please enter amenities name'),
            code: required('Please enter code'),

        })
    } else if (type === 'ADD_STAY') {
        return yup({
            stayType: required('Please enter stay type'),
            description: required('Please enter description'),
            code: required('Please enter code'),

        })
    } else if (type === 'ADD_EXP') {
        return yup({
            experienceName: required('Please enter exp type'),
            experienceDescription: required('Please enter description'),
        })
    } else if (type === 'ADD_EXP_SUB') {
        return yup({
            adventureName: required('Please enter exp sub type'),
            experienceTypeId: required('Please select exp type'),
            adventureDescription: required('Please enter description'),
        })
    } else if (type === 'ADD_EXP_INCLUDES') {
        return yup({
            includesName: required('Please enter name'),
            description: required('Please enter description'),
            code: required('Please enter code'),
        })
    } else if (type === 'ADD_FOOD') {
        return yup({
            foodName: required('Please enter food name')
        })
    } else if (type === 'ADD_TRANSPORTATION') {
        return yup({
            name: required('Please enter name'),
            description: required('Please enter description')
        })
    } else if (type === 'ADD_ON_TYPES') {
        return yup({
            addonName: required('Please enter name'),
            addonDescription: required('Please enter description')
        })
    } else if (type === 'ADD_PACKIGN_LIST') {
        return yup({
            type: required('Select Type'),
            name: required('Please enter name')
        })
    } else if (type === 'ADD_RULES') {
        return yup({
            rulesType: required('Select rules type'),
            rule: required('Please enter rules')
        })
    } else if (type === 'ADD_POLICY') {
        return yup({
            policyType: required('Select policy type'),
            policy: required('Please enter policy')
        })
    } else if (type === 'ADD_ADD_ONS') {
        return yup({
            addonTypeId: required('Select add on type'),
            price: required('Please enter price')
        })
    } else if (type === 'ADD_STAY_DETAIL') {
        return yup({
            stayTypeId: required('Select stay  type'),
        })
    } else if (type === 'ADD_EVENTS') {
        return yup({
            experienceTypeId: required('Select Exp type'),
        })
    }
    
    
};