import { useEffect, useState } from "react";
import userServices from "../../services/user.services";
import ConfirmationModal from "../CommonComponents/ConfirmationModal";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function EventDetail({ setShowDetail, setSelectedRow, selectedRow, getData}) {
  const [data, setData] = useState(selectedRow)

  const [confirmModal, setConfirmModal] = useState(false);
  const navigate = useNavigate();

  const toggleConfirm = () => setConfirmModal(!confirmModal);



const approved =()=>{

  userServices.approveExperience({id: data._id}).then((res)=>{
    if(res.status===200){
      toast.success("Experience approved successfully", {
        toastId: "update_success",
        autoClose: 3000,
      });
      getData()
      setShowDetail(false);
      setSelectedRow('');
      toggleConfirm()
    }
  })
}
  useEffect(() => {
  }, [])
  return (
    <div className="page-inner">
      {/* Main Wrapper */}
      <div id="main-wrapper">
        <div className="pageheader pd-t-25 pd-b-35 ">
          <div className="d-flex justify-content-between align-items-center pd-t-5 pd-b-5" >
            <h1 className="pd-0 mg-0 tx-20 text-overflow">Event Details</h1>

            <button
              type="button "
              className="btn return-btn btn-sm"
              onClick={() => { setShowDetail(false); setSelectedRow('') }}
            >
              Return To List
            </button>
          </div>
        </div>

        <div className="row clearfix">

          <div className="col-12">
            <div className="card p-md-4 p-3">
              <div className="row">
              <div className="col-sm-12 mb-3">
                  <div className="card mg-b-20">
                    <div className="card-body p-md-4 p-3 property-info">
                      <div className="card-label">Host Information</div>
                      <div className="d-flex justify-content-start align-items-baseline w-100 mb-2">
                        <h5>Host Name </h5>
                        <p>: {data?.hostName || 'Sonkupi Banjara Camp'} </p>
                      </div>

                      {/* <div className="d-flex justify-content-start align-items-baseline w-100 mb-2">
                        <h5>Conveyance Type  </h5>
                        <p>: {data?.conveyanceTypeName || 'Sonkupi Banjara Camp'} </p>
                      </div> */}
                      
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 mb-3">
                  <div className="card mg-b-20">
                    <div className="card-body p-md-4 p-3 property-info">
                      <div className="card-label">Experience Information</div>
                      <div className="d-flex justify-content-start align-items-baseline w-100 mb-2">
                        <h5>Experience Type  </h5>
                        <p>: {data?.experienceTypeName || 'Sonkupi Banjara Camp'} </p>
                      </div>

                      <div className="d-flex justify-content-start align-items-baseline w-100 mb-2">
                        <h5>Adventure Type  </h5>
                        <p>: {data?.adventureName || 'Sonkupi Banjara Camp'} </p>
                      </div>


                      <div className="d-flex justify-content-start align-items-baseline w-100 mb-2">
                        <h5>Duration Type  </h5>
                        <p>: {data?.durationName || 'Sonkupi Banjara Camp'} </p>
                      </div>

                      <div className="d-flex justify-content-start align-items-baseline w-100 mb-2">
                        <h5>Experience  </h5>
                        <p>: {data?.experienceName || 'Sonkupi Banjara Camp'} </p>
                      </div>

                      <div className="d-flex justify-content-start align-items-baseline w-100 mb-2">
                            <h5>Price</h5>
                            <p className="d-flex">
                              <div className="card p-2">Regular Price (₹) <div className="badge badge-success mt-1">{data?.priceRegular || '00.00'}</div></div>
                              <div className="card p-2 ml-2">Weekend Price (₹) <div className="badge badge-success mt-1">{data?.priceWeekend || '00.00'}</div></div>
                            </p>
                          </div>
                          <div className="d-flex justify-content-start align-items-baseline w-100 mb-2">
                            <h5>Includes </h5>
                            <p >:
                            {data?.includesData.map((item) => {
                            return (
                               <> <span className="badge badge-success">{item?.includesName || ''} </span>{" "}</>
                            );
                          })}
                          </p>
                          </div>
                          <div className="d-flex justify-content-start align-items-baseline w-100 mb-2">
                            <h5>Carry </h5>
                            <p >:
                            {data?.carryData.map((item) => {
                            return (
                               <> <span className="badge badge-success">{item?.carryName || ''} </span>{" "}</>
                            );
                          })}
                          </p>
                          </div>
                          
 

                      
                    </div>
                  </div>
                </div>

        
            {data?.experience_status===1 &&    <div className="col-12">
                <button type="button" onClick={toggleConfirm} class="btn return-btn btn-sm">Approve</button>
                </div>
                }

              </div>
            </div>
          </div>


          <ConfirmationModal 
              toggle={toggleConfirm} 
              modal={confirmModal} 
              onClick={approved} 
              title="Confirm Approved"
              text="Are you sure you want to approve this event?"
              iconType='mdi-alert-circle'
            />
        </div>
      </div>
      {/*/ Main Wrapper End */}
    </div>
  );
}

export default EventDetail;
