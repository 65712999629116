import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getLoggedData } from "../../helpers/Helper";

const Header = ({ menuIconOnClick }) => {
    const [userData, setUserData]=useState('')
   useEffect(()=>{
    const loggedData = getLoggedData();
    setUserData(loggedData.user)
   },[])
    return (
        <React.Fragment>
         <div className="page-header">
  <div className="search-form">
    <form action="#" method="GET">
      <div className="input-group">
        <input
          className="form-control search-input"
          name="search"
          placeholder="Type something..."
          type="text"
        />
        <span className="input-group-btn">
          <span id="close-search">
            <i className="ion-ios-close-empty" />
          </span>
        </span>
      </div>
    </form>
  </div>
  {/*================================*/}
  {/* Page Header  Start */}
  {/*================================*/}
  <nav className="navbar navbar-expand-lg justify-content-between">
    <ul className="list-inline list-unstyled mg-r-20">
      {/* Mobile Toggle and Logo */}
      <li className="list-inline-item align-text-top">
        <a href className="hidden-md hidden-lg" onClick={menuIconOnClick} id="sidebar-toggle-button">
          <i className="ion-navicon tx-20" />
        </a>
      </li>
      {/* PC Toggle and Logo */}
      <li className="list-inline-item align-text-top">
        <a
          href
          className="hidden-xs hidden-sm"
          onClick={menuIconOnClick}
          id="collapsed-sidebar-toggle-button"
        >
          <i className="ion-navicon tx-20" />
        </a>
      </li>
    </ul>
    {/*================================*/}
    {/* Mega Menu Start */}
    {/*================================*/}
    
    {/*/ Mega Menu End*/}
    {/*/ Brand and Logo End */}
    {/*================================*/}
    {/* Header Right Start */}
    {/*================================*/}
    <div className="header-right pull-right">
      <ul className="list-inline justify-content-end">
        
        <li className="list-inline-item dropdown">
          <a
            href
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <span className="select-profile">{userData?.name||'Hi, Admin!'}</span>
            <img
              src="/assets/images/avatar/avatar1.png"
              className="img-fluid wd-35 ht-35 rounded-circle"
              alt=""
            />
          </a>
          <div className="dropdown-menu dropdown-menu-right dropdown-profile shadow-2">
            <div className="user-profile-area">
              <div className="user-profile-heading">
                <div className="profile-thumbnail">
                  <img
                    src="/assets/images/avatar/avatar1.png"
                    className="img-fluid wd-35 ht-35 rounded-circle"
                    alt=""
                  />
                </div>
                <div className="profile-text">
                  <h6>{userData?.name|| 'Admin'}</h6>
                  <span>{userData?.email || "admin@gmail.com"}</span>
                </div>
              </div>
              
              {/* <a href="page-singin.html" className="dropdown-item">
                <i className="icon-power" aria-hidden="true" /> Sign-out
              </a> */}
               <Link to="/profile" className="dropdown-item" >
               <i className="icon-user" aria-hidden="true" />
                                                <span>Profile</span>
                                            </Link>

                                            <Link to="/logout" className="dropdown-item" >
               <i className="icon-power" aria-hidden="true" />
                                                <span>Sign-out</span>
                                            </Link>
            </div>
          </div>
        </li>
        {/* Profile Dropdown End */}
        {/*================================*/}
        
      </ul>
    </div>
    {/*/ Header Right End */}
  </nav>
</div>


        </React.Fragment>
    )
}

export default Header;