import React from "react";
const Footer = () => {

    return (
        <React.Fragment>
               <footer className="page-footer mt-3">
            <div className="pd-t-4 pd-b-0 pd-x-20 text-center">
                <div className="tx-10 tx-uppercase">
                <p className="pd-y-10 mb-0">
                    Copyright© 2021 | All rights reserved. | Created By{" "}
                    {/* <a href="http://wrapcoders.xyz/" target="_blank">
                    WRAPCODERS
                    </a> */}
                </p>
                </div>
            </div>
        </footer>
        </React.Fragment >
    )
}

export default Footer;