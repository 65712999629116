import React, { useEffect, useState } from "react";
import NavBar from "./NavBar";
import SideBar from "../Layout/SideBar";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";

const Layout = () => {
    const [toggle, setToggle] = useState(false);

    // const [offset, setOffset] = useState(0);


    const menuIconOnClick = () => {
        setToggle((prevState) => prevState ? false : true)
    }
    useEffect(() => {
        console.warn = () => { };  // Disable all warnings
        console.error = () => { }; // Disable all error messages (not recommended)
        console.info = () => { };
        const onScroll = () => {
            // setOffset(window.pageYOffset)
            // console.log('offset', window.pageYOffset)
        };
        // clean up code
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);
    }, []);
    return (

        <div className={`page-container ${toggle ? 'page-sidebar-collapsed' : ''}`}>
            <SideBar menuIconOnClick={menuIconOnClick} />
            <div className="page-content">
                <NavBar menuIconOnClick={menuIconOnClick} />
                <Outlet />

                <Footer />
            </div>
        </div>

    );
}

export default Layout;
