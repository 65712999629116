import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { toast } from 'react-toastify';
import { FieldSchema } from '../../schema/FieldSchema';
import TextError from '../../helpers/FormikHelper/TextError';
import userServices from '../../services/user.services';

const FoodForm = ({ toggle, isAddMode, selectedRow, getData }) => {
    // const [file, setFile] = useState(selectedRow?.channelLogo);
    // function handleChange(e) {
    //     if (e.target.files.length > 0) {
    //         setFile(URL.createObjectURL(e.target.files[0]));
    //     } else {
    //         setFile('')
    //     }
    // }

    const initialValues = {
        foodName: '',
    }

    const savedValues = {
        foodName: selectedRow?.foodName,
    }

    const onSubmit = async (values) => {
        if (isAddMode === 'Add') {
            let result = await userServices.addFood(values);
            if (result.data.status === 'success') {
                toggle()
                getData()
                toast.success('Data Added successfully', { toastId: 'registration_success', autoClose: 3000 });
            } else {
                toast.error(result.data.message, { toastId: 'registration_fail', autoClose: 3000 });
            }
        } else {
            values.id = selectedRow?._id
            const result = await userServices.updateFood(values);
            if (result.data.status === 'success') {
                toggle()
                getData()
                toast.success('Data Updated successfully', { toastId: 'update_success', autoClose: 3000 });
            } else {
                toast.error(result.data.message, { toastId: 'update_fail', autoClose: 3000 });

            }
        }
    }

    return (
        <React.Fragment>
            <Formik
                initialValues={isAddMode === 'Add' ? initialValues : savedValues}
                validationSchema={FieldSchema(`${isAddMode === 'Add' ? 'ADD_FOOD' : 'ADD_FOOD'}`)}
                onSubmit={onSubmit}
                enableReinitialize
            >
                {({ setFieldValue, values }) => (<Form>
                    <ModalHeader toggle={toggle} className='border-0 pb-0'>
                        <div className="card-title d-flex align-items-center mb-0">
                            <h5 className="mb-0 text-primary">{isAddMode === 'Add' ? 'Add Food' : 'Update Food'}</h5>
                        </div>
                    </ModalHeader>
                    <ModalBody className="">
                        <div className="row" >
                            <div className="col-12 mb-3">
                                <label htmlFor="foodName" className="form-label">
                                    Food Name
                                </label>
                                <Field type="text" className="form-control" id="foodName" name='foodName' />
                                <ErrorMessage name='foodName' component={TextError} />
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter className='border-0 pt-0'>
                        <button
                            type="button"
                            className="btn btn-secondary btn-sm"
                            onClick={toggle}
                        >
                            Close
                        </button>
                        <button type="submit" className="btn btn-primary btn-sm">
                            {isAddMode === "Add" ? `Save` : `Update`}
                        </button>
                    </ModalFooter>
                </Form>
                )}
            </Formik>
        </React.Fragment>
    );
};

export default FoodForm;