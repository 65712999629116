import React, { useMemo } from 'react';
import { useTable, useSortBy, usePagination, useGlobalFilter } from 'react-table';
import { useNavigate } from "react-router-dom";

// Define a default UI for filtering
const GlobalFilter = ({ globalFilter, setGlobalFilter, pageSize, setPageSize }) => {

  return (
    <div className='pagination-top d-flex justify-content-between align-items-center'>
      <span>
        <select
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </span>

      <span>
        Search: {' '}
        <input
          value={globalFilter || ''}
          onChange={e => {
            setGlobalFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
          }}
          placeholder="Type to search..."
        />
      </span>
    </div>
  );
};

const ExperienceList = ({ data, setSelectedRow, setIsAddMode, setModal, getData, setShowDetail }) => {
  let srNo = 1
  for (let i of data) {
    i.srNo = srNo;
    srNo++;
  }
  const columns = useMemo(
    () => [
      { Header: 'SrNo.', accessor: 'srNo', show: false },
      { Header: 'Experience Name', accessor: 'experienceName' },
      { Header: 'Experience Types', accessor: 'experienceType' },
      { Header: 'Duration', accessor: 'durationName' },
      { Header: 'Host Name', accessor: 'hostName' },
      { Header: 'Created Date', accessor: 'createdAt' },
      {
        Header: 'Action',
        accessor: 'edit',
        Cell: ({ row }) => (
          <><button className="action_btn_ic" onClick={() => {
            setSelectedRow(row.original);
            setIsAddMode('Edit');
            setModal(true)

          }}><i className='fa fa-edit'></i></button>

            {/* <button className="action_btn_ic pointer" onClick={() => {
              setSelectedRow(row.original);
              setShowDetail(true)
            }}><i className='fa fa-eye'></i></button> */}
          </>
        )
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    setGlobalFilter,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, globalFilter },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useGlobalFilter, // Use global filter hook
    useSortBy,
    usePagination
  );

  return (
    <div>

      <GlobalFilter globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} setPageSize={setPageSize} pageSize={pageSize} />
      <table id="basicDataTable" className="table table-responsive-sm nowrap" {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => {
            const { key, ...restHeaderGroupProps } = headerGroup.getHeaderGroupProps();
            return (
              <tr key={key} {...restHeaderGroupProps}>
                {headerGroup.headers.map(column => {
                  const { key, ...restColumnProps } = column.getHeaderProps(column.getSortByToggleProps());
                  return (
                    <th key={key} {...restColumnProps}>
                      {column.render('Header')}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? ' 🔽'
                            : ' 🔼'
                          : ''}
                      </span>
                    </th>
                  );
                })}
              </tr>
            );
          })}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map(row => {
            prepareRow(row);
            const { key, ...restRowProps } = row.getRowProps();
            return (
              <tr key={key} {...restRowProps}>
                {row.cells.map(cell => {
                  const { key, ...restCellProps } = cell.getCellProps();
                  return (
                    <>
                      <td key={key} {...restCellProps}>
                        {cell.render('Cell')}
                      </td>

                    </>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className='navigation-btn'>
        <span>
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </span>
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {'<<'}
        </button>
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {'<'}
        </button>
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {'>'}
        </button>
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {'>>'}
        </button>

      </div>
    </div>
  );
};

export default ExperienceList;
