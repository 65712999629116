import { ErrorMessage, Field, Form, Formik } from 'formik';
import React from 'react';
import { ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { toast } from 'react-toastify';
import TextError from '../../helpers/FormikHelper/TextError';
import userServices from '../../services/user.services';
import { FieldSchema } from '../../schema/FieldSchema';

const PakingListForm = ({ toggle, isAddMode, selectedRow, getData }) => {
    // const [file, setFile] = useState(isAddMode === 'Add' ? '' : `${BASE_URL}/${selectedRow?.adventureImg}`);
    // const [expTypes, setExpTypes] = useState([])
    // function handleChange(e) {
    //     if (e.target.files.length > 0) {
    //         setFile(URL.createObjectURL(e.target.files[0]));
    //     } else {
    //         setFile('')
    //     }
    // }

    const initialValues = {
        type: '',
        name: '',
        description:'',
        linkTitle:'',
        link: ''
    }

    const savedValues = {
        type: selectedRow?.type,
        name: selectedRow?.name,
        description: selectedRow?.description,
        linkTitle: selectedRow?.linkTitle,
        link: selectedRow?.link
    }

    const onSubmit = async (values) => {

        if (isAddMode === 'Add') {
            let result = await userServices.addPackingList(values);
            if (result.data.status === 'success') {
            
                toggle()
                getData()
                toast.success('Data Added successfully', { toastId: 'registration_success', autoClose: 3000 });
            } else {
                toast.error(result.data.message, { toastId: 'registration_fail', autoClose: 3000 });
            }
        } else {
            values.id = selectedRow?._id
            const result = await userServices.updatePackingList(values);
            if (result.data.status === 'success') {
         
                toggle()
                getData()
                toast.success('Data Updated successfully', { toastId: 'update_success', autoClose: 3000 });
            } else {
                toast.error(result.data.message, { toastId: 'update_fail', autoClose: 3000 });

            }
        }
    }


    return (
        <React.Fragment>
            <Formik
                initialValues={isAddMode === 'Add' ? initialValues : savedValues}
                validationSchema={FieldSchema(`${isAddMode === 'Add' ? 'ADD_PACKIGN_LIST' : 'ADD_PACKIGN_LIST'}`)}
                onSubmit={onSubmit}
                enableReinitialize
            >
                {({ setFieldValue, values }) => (<Form>
                    <ModalHeader toggle={toggle} className='border-0 pb-0'>
                        <div className="card-title d-flex align-items-center mb-0">
                            <h5 className="mb-0 text-primary">{isAddMode === 'Add' ? 'Add Guest Packing List' : 'Update Guest Packing List'}</h5>
                        </div>
                    </ModalHeader>
                    <ModalBody className="">
                        <div className="row" >
                            <div className="col-md-12 mb-3">
                                <label htmlFor="type" className="form-label">
                                   Type
                                </label>
                                <Field as='select' className='form-select' id="type" name="type" >
                                    <option disabled value="">Type</option>
                                    <option value="Documents">Documents</option>
                                    <option value="Core Gears">Core Gears</option>
                                    <option value="Clothings">Clothings</option>
                                    <option value="Post Covid Essentials">Post Covid Essentials</option>
                                    <option value="Group Share">Group Share</option>
                                    <option value="Personal Care">Personal Care</option>
                                    <option value="Electronics">Electronics</option>
                                    <option value="Misch">Misch</option>
                                   
                                </Field>
                                <ErrorMessage name="type" component={TextError} />
                            </div>
                            <div className="col-12 mb-3">
                                <label htmlFor="name" className="form-label">
                                    Name
                                </label>
                                <Field type="text" className="form-control" id="name" name='name' />
                                <ErrorMessage name='name' component={TextError} />
                            </div>
                            <div className="col-12 mb-3">
                                <label htmlFor="description" className="form-label">
                                    Description
                                </label>
                                <Field type="description" className="form-control" id="description" name='description'
                                />
                                <ErrorMessage name='description' component={TextError} />
                            </div>
                            <div className="col-12 mb-3">
                                <label htmlFor="description" className="form-label">
                                Link Title
                                </label>
                                <Field type="linkTitle" className="form-control" id="linkTitle" name='linkTitle'
                                />
                                <ErrorMessage name='linkTitle' component={TextError} />
                            </div>
                            <div className="col-12 mb-3">
                                <label htmlFor="link" className="form-label">
                                    Link
                                </label>
                                <Field type="link" className="form-control" id="link" name='link'
                                />
                                <ErrorMessage name='link' component={TextError} />
                            </div>
                            
                        </div>
                    </ModalBody>
                    <ModalFooter className='border-0 pt-0'>
                        <button
                            type="button"
                            className="btn btn-secondary btn-sm"
                            onClick={() => {
                                toggle();
                            }}
                        >
                            Close
                        </button>
                        <button type="submit" className="btn btn-primary btn-sm">
                            {isAddMode === "Add" ? `Save` : `Update`}
                        </button>
                    </ModalFooter>
                </Form>
                )}
            </Formik>
        </React.Fragment>
    );
};

export default PakingListForm;