import { useEffect, useState } from "react";
import userServices from "../services/user.services";
import PropertiestList from "../components/Properties/PropertiesList";
import PropertyDetail from "../components/Properties/PropertyDetailPage";
import PropertiesForm from "../components/Properties/PropertiesForm";
import { Modal } from "reactstrap";

function Properties() {
  const [data, setData] = useState([])
  const [showPropertyDetail, setShowPropertyDetail] = useState(false)
  const [propertyId, setPropertyId] = useState('')
  const [selectedRow, setSelectedRow] = useState();
  const [modal, setModal] = useState(false);
  const [isAddMode, setIsAddMode] = useState('Add');
  const toggle = () => setModal(!modal);
  const getData = async () => {
    const result = await userServices.approvedPropertiesList()
    if (result.status === 200) {
      setData(result.data.data)

    }
  }

  useEffect(() => {
    getData()
  }, [])
  return (
    <div className="page-inner">
      {/* Main Wrapper */}
      {showPropertyDetail === false ?
        <div id="main-wrapper">
          <div className="pageheader pd-t-25 pd-b-35">
            <div className="pd-t-5 pd-b-5">
              <h1 className="pd-0 mg-0 tx-24 text-overflow">Active Properties</h1>
            </div>
          </div>

          <div className="row row-xs clearfix">
            <div className="col-md-12 col-lg-12">
              <div className="card mg-b-20">
                <div className="card-header">
                  {/* <h4 className="card-header-title">Users List</h4> */}
                  <div className="card-header-btn">
                    <a
                      href
                      data-toggle="collapse"
                      className="btn card-collapse"
                      data-target="#collapse1"
                      aria-expanded="true"
                    >
                      <i className="ion-ios-arrow-down"></i>
                    </a>
                  </div>

                  <div className="btn-group"></div>
                </div>

                <div className="card-body collapse show" id="collapse1">

                  <PropertiestList
                    users={data}
                    setShowPropertyDetail={setShowPropertyDetail}
                    setPropertyId={setPropertyId}
                    setSelectedRow={setSelectedRow}
                    setIsAddMode={setIsAddMode}
                    setModal={setModal}

                  />
                </div>
              </div>
            </div>
          </div>
          <Modal isOpen={modal} toggle={toggle} size="md" centered={true} className="">
            <PropertiesForm toggle={toggle} isAddMode={isAddMode} selectedRow={selectedRow} getData={getData} />

          </Modal>
        </div>
        :
        <PropertyDetail setShowPropertyDetail={setShowPropertyDetail} propertyId={propertyId} etPropertyId={setPropertyId} />
      }
      {/*/ Main Wrapper End */}
    </div>
  );
}

export default Properties;
