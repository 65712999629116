import {API, BASE_URL} from './config';

const authServices = {
    loginApi: async (reqBody)=>{
        try { 
            const response = await API.post(`${BASE_URL}/auth/login`,reqBody);
            return response;
        } catch (error) {
            return error.response;
        }
    }
}
export default authServices