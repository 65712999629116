import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { toast } from 'react-toastify';
import { FieldSchema } from '../../schema/FieldSchema';
import TextError from '../../helpers/FormikHelper/TextError';
import userServices from '../../services/user.services';

const LanguageForm = ({ toggle, isAddMode, selectedRow, getData }) => {
    // const [file, setFile] = useState(selectedRow?.channelLogo);
    // function handleChange(e) {
    //     if (e.target.files.length > 0) {
    //         setFile(URL.createObjectURL(e.target.files[0]));
    //     } else {
    //         setFile('')
    //     }
    // }

    const initialValues = {
        language: '',
        countryCode: '',
        code: '',
        default: false,
    }

    const savedValues = {
        language: selectedRow?.language,
        countryCode: selectedRow?.countryCode,
        code: selectedRow?.code,
        default: selectedRow?.default
    }

    const onSubmit = async (values) => {
        // const reqBody = {
        //     name: values?.name,
        //     channelNumber: values?.channelNumber,
        //     liveStreamUrl: values?.liveStreamUrl,
        //     category: values?.category,
        //     genre: values?.genre,
        //     file: values?.file
        // }
        if (isAddMode === 'Add') {
            let result = await userServices.addLanguage(values);
            if (result.data.status === 'success') {
                toggle()
                getData()
                toast.success('Data Added successfully', { toastId: 'registration_success', autoClose: 3000 });
            } else {
                toast.error(result.data.message, { toastId: 'registration_fail', autoClose: 3000 });
            }
        } else {
            values.id = selectedRow?._id
            const result = await userServices.updateLanguage(values);
            if (result.data.status === 'success') {
                toggle()
                getData()
                toast.success('Data Updated successfully', { toastId: 'update_success', autoClose: 3000 });
            } else {
                toast.error(result.data.message, { toastId: 'update_fail', autoClose: 3000 });

            }
        }
    }

    return (
        <React.Fragment>
            <Formik
                initialValues={isAddMode === 'Add' ? initialValues : savedValues}
                validationSchema={FieldSchema(`${isAddMode === 'Add' ? 'ADD_LANGUAGE' : 'ADD_LANGUAGE'}`)}
                onSubmit={onSubmit}
                enableReinitialize
            >
                {({ setFieldValue, values }) => (<Form>
                    <ModalHeader toggle={toggle} className='border-0 pb-0'>
                        <div className="card-title d-flex align-items-center mb-0">
                            <h5 className="mb-0 text-primary">{isAddMode === 'Add' ? 'Add Language' : 'Update Language'}</h5>
                        </div>
                    </ModalHeader>
                    <ModalBody className="">
                        <div className="row" >
                            <div className="col-12 mb-3">
                                <label htmlFor="language" className="form-label">
                                    Language
                                </label>
                                <Field type="text" className="form-control" id="language" name='language' />
                                <ErrorMessage name='language' component={TextError} />
                            </div>
                            <div className="col-12 mb-3">
                                <label htmlFor="countryCode" className="form-label">
                                    Country Code
                                </label>
                                <Field type="countryCode" className="form-control" id="countryCode" name='countryCode'
                                />
                                <ErrorMessage name='countryCode' component={TextError} />
                            </div>
                            <div className="col-12 mb-3">
                                <label htmlFor="code" className="form-label">
                                    Code
                                </label>
                                <Field type="code" className="form-control" id="code" name='code'
                                />
                                <ErrorMessage name='code' component={TextError} />
                            </div>
                            <div className="col-12 mb-3">
                                <div className="form-check">
                                    <Field
                                        className="form-check-input"
                                        type="checkbox"
                                        name="default"
                                        id="default"
                                    />
                                    <label className="form-check-label" for="flexCheckDefault">
                                        Default
                                    </label>
                                </div>

                                {/* <ErrorMessage name='default' component={TextError} /> */}
                            </div>

                            {/* <div className="col-md-12 mb-3">
                                <label htmlFor="category" className="form-label">
                                    User Type
                                </label>
                                <Field as='select' className='form-select' id="category" name="category" >
                                    <option disabled value="">Select Category</option>
                                
                                    {
                                        categoryList.map((e, key) => {
                                            return <option key={key} value={e.id}>{e.name}</option>;
                                        })
                                    }
                                </Field>
                                <ErrorMessage name="category" component={TextError} />
                            </div>
                            <div className="col-12">
                                <label htmlFor="genre" className="form-label">
                                    Genre
                                </label>
                                <Field as='select' className='form-select' id="genre" name="genre" >
                                    <option disabled value="">Select Genre</option>
                                    {
                                        genreList.map((e, key) => {
                                            return <option key={key} value={e.id}>{e.name}</option>;
                                        })
                                    }
                                </Field>
                                <ErrorMessage name='genre' component={TextError} />
                            </div>

                            <div className="col-12 mb-3">
                                <label htmlFor="liveStreamUrl" className="form-label">
                                    Live Stream Url
                                </label>
                                <Field type="liveStreamUrl" className="form-control" id="liveStreamUrl" name='liveStreamUrl'
                                />
                                <ErrorMessage name='liveStreamUrl' component={TextError} />
                            </div> */}
                            {/* <div className="col-12 mb-3">
                                <label htmlFor="file" className="form-label">
                                    Channel Logo
                                </label>

                                <input id="file" name="file" type="file" onChange={(event) => {
                                    setFieldValue("file", event.currentTarget.files[0]);
                                    handleChange(event)
                                }} className="form-control" />
                                {file === undefined || file === '' ? '' : <img src={file}
                                    alt={selectedRow?.channelLogo}
                                    className="img-thumbnail mt-2"
                                    height={150}
                                    width={150} />}

                            </div> */}
                        </div>
                    </ModalBody>
                    <ModalFooter className='border-0 pt-0'>
                        <button
                            type="button"
                            className="btn btn-secondary btn-sm"
                            onClick={toggle}
                        >
                            Close
                        </button>
                        <button type="submit" className="btn btn-primary btn-sm">
                            {isAddMode === "Add" ? `Save` : `Update`}
                        </button>
                    </ModalFooter>
                </Form>
                )}
            </Formik>
        </React.Fragment>
    );
};

export default LanguageForm;