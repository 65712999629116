import {API, BASE_URL} from './config';

const userServices = {
    getAllHost: async ()=>{
        try {
            const response = await API.get(`${BASE_URL}/user/host-list`);
            
            return response;
        } catch (error) {
            
            return error.response;
        }
    },

    getProperties: async ()=>{
        try {
            const response = await API.get(`${BASE_URL}/property/list`);
            
            return response;
        } catch (error) {
            
            return error.response;
        }
    },

    approvedPropertiesList: async ()=>{
        try {
            const response = await API.get(`${BASE_URL}/property/approved-list`);
            
            return response;
        } catch (error) {
            
            return error.response;
        }
    },

    unApprovedPropertiesList: async ()=>{
        try {
            const response = await API.get(`${BASE_URL}/property/unapproved-list`);
            
            return response;
        } catch (error) {
            
            return error.response;
        }
    },

    approveProperty: async (reqBody) => {
        try {
            const response = await API.post(`${BASE_URL}/property/approve`,reqBody);
            return response;
        } catch (error) {
            return error.response;
        }
    },

    approvedExperienceList: async ()=>{
        try {
            const response = await API.get(`${BASE_URL}/experience/exp-approved-list`);
            
            return response;
        } catch (error) {
            
            return error.response;
        }
    },

    unApprovedExperienceList: async ()=>{
        try {
            const response = await API.get(`${BASE_URL}/experience/unapproved-exp-list`);
            
            return response;
        } catch (error) {
            
            return error.response;
        }
    },

    approveExperience: async (reqBody) => {
        try {
            const response = await API.post(`${BASE_URL}/experience/approve-exp`,reqBody);
            return response;
        } catch (error) {
            
            return error.response;
        }
    },
    
    userStatus: async (reqBody) => {
        try {
            const response = await API.post(`${BASE_URL}/user/update-user-status`,reqBody);
            return response;
        } catch (error) {
            
            return error.response;
        }
    },
    ChnagePassword: async (reqBody) => {
        try {
            const response = await API.post(`${BASE_URL}/auth/update-password`, reqBody);
            return response;
        } catch (error) {
            return error.response;
        }
    },
    propertiesDetail: async (reqBody) => {
        try {
            const response = await API.post(`${BASE_URL}/property/property-by-id`, reqBody);
            return response;
        } catch (error) {
            return error.response;
        }
    },
    propertiesDetailByHost: async (reqBody) => {
        try {
            const response = await API.post(`${BASE_URL}/property/list-by-host-id`, reqBody);
            return response;
        } catch (error) {
            return error.response;
        }
    },
    deleteUser: async (reqBody) => {
        try {
            const response = await API.post(`${BASE_URL}/user/delete-profile-by-admin`, reqBody);
            return response;    
        } catch (error) {
            return error.response;
        }
    },
    createUser: async (reqBody) => {
        try {
            const response = await API.post(`${BASE_URL}/auth/register`, reqBody);
            return response;    
        } catch (error) {
            return error.response;
        }
    },
    updateUser: async (reqBody) => {
        try {
            const response = await API.post(`${BASE_URL}/user/update-profile`, reqBody);
            return response;    
        } catch (error) {
            return error.response;
        }
    },

    activeConveyanceList: async ()=>{
        try {
            const response = await API.get(`${BASE_URL}/conveyance/active-conv-list`);
            
            return response;
        } catch (error) {
            
            return error.response;
        }
    },

    publishedConveyanceList: async ()=>{
        try {
            const response = await API.get(`${BASE_URL}/conveyance/published-conv-list`);
            
            return response;
        } catch (error) {
            
            return error.response;
        }
    },

    approveConveyance: async (reqBody) => {
        try {
            const response = await API.post(`${BASE_URL}/conveyance/approve-conv`,reqBody);
            return response;
        } catch (error) {
            
            return error.response;
        }
    },

    tripBookingList: async ()=>{
        try {
            const response = await API.get(`${BASE_URL}/booking/booking-list-for-admin`);
            
            return response;
        } catch (error) {
            
            return error.response;
        }
    },
    refundPayment: async (reqBody) => {
        try {
            const response = await API.post(`${BASE_URL}/booking/refund-payment`,reqBody);
            return response;
        } catch (error) {
            
            return error.response;
        }
    },
    
    languagesList: async ()=>{
        try {
            const response = await API.get(`${BASE_URL}/property/languages-list`);
            
            return response;
        } catch (error) {
            
            return error.response;
        }
    },

    addLanguage: async (reqBody) => {
        try {
            const response = await API.post(`${BASE_URL}/property/add-languages`,reqBody);
            return response;
        } catch (error) {
            return error.response;
        }
    },

    updateLanguage: async (reqBody) => {
        try {
            const response = await API.post(`${BASE_URL}/property/update-language`,reqBody);
            return response;
        } catch (error) {
            return error.response;
        }
    },

    seasionList: async ()=>{
        try {
            const response = await API.get(`${BASE_URL}/property/seasons-list`);
            
            return response;
        } catch (error) {
            
            return error.response;
        }
    },
    
    addSeason: async (reqBody) => {
        try {
            const response = await API.post(`${BASE_URL}/property/add-seasons`,reqBody,{
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              });
            return response;
        } catch (error) {
            return error.response;
        }
    },

    
    updateSeason: async (reqBody) => {
        try {
            const response = await API.post(`${BASE_URL}/property/update-seasons`,reqBody,{
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              });
            return response;
        } catch (error) {
            return error.response;
        }
    },


    terrianList: async ()=>{
        try {
            const response = await API.get(`${BASE_URL}/property/terrian-list`);
            
            return response;
        } catch (error) {
            
            return error.response;
        }
    },
    
    addTerrian: async (reqBody) => {
        try {
            const response = await API.post(`${BASE_URL}/property/add-terrain`,reqBody,{
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              });
            return response;
        } catch (error) {
            return error.response;
        }
    },

    
    updateTerrian: async (reqBody) => {
        try {
            const response = await API.post(`${BASE_URL}/property/update-terrain`,reqBody,{
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              });
            return response;
        } catch (error) {
            return error.response;
        }
    },

    amenitiesList: async ()=>{
        try {
            const response = await API.get(`${BASE_URL}/property/aminites-list`);
            
            return response;
        } catch (error) {
            
            return error.response;
        }
    },
    
    addAmenities: async (reqBody) => {
        try {
            const response = await API.post(`${BASE_URL}/property/add-amenities`,reqBody,{
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              });
            return response;
        } catch (error) {
            return error.response;
        }
    },

    
    updateAmenities: async (reqBody) => {
        try {
            const response = await API.post(`${BASE_URL}/property/update-amenites`,reqBody,{
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              });
            return response;
        } catch (error) {
            return error.response;
        }
    },

    stayTypeList: async ()=>{
        try {
            const response = await API.get(`${BASE_URL}/property/stay-type-list`);
            
            return response;
        } catch (error) {
            
            return error.response;
        }
    },
    
    addStayType: async (reqBody) => {
        try {
            const response = await API.post(`${BASE_URL}/property/add-stay-types`,reqBody,{
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              });
            return response;
        } catch (error) {
            return error.response;
        }
    },

    
    updateStayType: async (reqBody) => {
        try {
            const response = await API.post(`${BASE_URL}/property/update-stay-types`,reqBody,{
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              });
            return response;
        } catch (error) {
            return error.response;
        }
    },

    expTypeList: async ()=>{
        try {
            const response = await API.get(`${BASE_URL}/experience/experience-type-list`);
            
            return response;
        } catch (error) {
            
            return error.response;
        }
    },
    addExpTypes: async (reqBody) => {
        try {
            const response = await API.post(`${BASE_URL}/experience/add-experience-type`,reqBody,{
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              });
            return response;
        } catch (error) {
            return error.response;
        }
    },
    updateExpTypes: async (reqBody) => {
        try {
            const response = await API.post(`${BASE_URL}/experience/update-experience-type`,reqBody,{
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              });
            return response;
        } catch (error) {
            return error.response;
        }
    },
    expSubTypeList: async ()=>{
        try {
            const response = await API.get(`${BASE_URL}/experience/adventure-type-list`);
            
            return response;
        } catch (error) {
            
            return error.response;
        }
    },
    addExpSubTypes: async (reqBody) => {
        try {
            const response = await API.post(`${BASE_URL}/experience/add-adventure-type`,reqBody,{
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              });
            return response;
        } catch (error) {
            return error.response;
        }
    },
    updateExpSubTypes: async (reqBody) => {
        try {
            const response = await API.post(`${BASE_URL}/experience/update-adventure-type`,reqBody,{
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              });
            return response;
        } catch (error) {
            return error.response;
        }
    },


    expIncludesList: async ()=>{
        try {
            const response = await API.get(`${BASE_URL}/experience/exp-include-list`);
            
            return response;
        } catch (error) {
            
            return error.response;
        }
    },
    addExpIncludes: async (reqBody) => {
        try {
            const response = await API.post(`${BASE_URL}/experience/add-exp-includes`,reqBody,{
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              });
            return response;
        } catch (error) {
            return error.response;
        }
    },
    updateExpIncludes: async (reqBody) => {
        try {
            const response = await API.post(`${BASE_URL}/experience/update-exp-includes`,reqBody,{
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              });
            return response;
        } catch (error) {
            return error.response;
        }
    },

    foodList: async ()=>{
        try {
            const response = await API.get(`${BASE_URL}/property/food-list`);
            
            return response;
        } catch (error) {
            
            return error.response;
        }
    },

    
    addFood: async (reqBody) => {
        try {
            const response = await API.post(`${BASE_URL}/property/add-food`,reqBody);
            return response;
        } catch (error) {
            return error.response;
        }
    },

    updateFood: async (reqBody) => {
        try {
            const response = await API.post(`${BASE_URL}/property/update-food-by-id`,reqBody);
            return response;
        } catch (error) {
            return error.response;
        }
    },


    transportationModeList: async ()=>{
        try {
            const response = await API.get(`${BASE_URL}/experience/transport-mode-list`);
            
            return response;
        } catch (error) {
            
            return error.response;
        }
    },

    addTransporationMode: async (reqBody) => {
        try {
            const response = await API.post(`${BASE_URL}/experience/create-transport-mode`,reqBody,{
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              });
            return response;
        } catch (error) {
            return error.response;
        }
    },
    updateTransporationMode: async (reqBody) => {
        try {
            const response = await API.post(`${BASE_URL}/experience/update-transport-mode`,reqBody,{
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              });
            return response;
        } catch (error) {
            return error.response;
        }
    },

    addOnTypeList: async ()=>{
        try {
            const response = await API.get(`${BASE_URL}/property/addons-type-list`);
            
            return response;
        } catch (error) {
            
            return error.response;
        }
    },

    addAddOnType: async (reqBody) => {
        try {
            const response = await API.post(`${BASE_URL}/property/add-addons-type`,reqBody,{
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              });
            return response;
        } catch (error) {
            return error.response;
        }
    },
    updateAddOnType: async (reqBody) => {
        try {
            const response = await API.post(`${BASE_URL}/property/update-addons-type`,reqBody,{
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              });
            return response;
        } catch (error) {
            return error.response;
        }
    },

    packingList: async ()=>{
        try {
            const response = await API.get(`${BASE_URL}/property/packing-list`);
            
            return response;
        } catch (error) {
            
            return error.response;
        }
    },

    
    addPackingList: async (reqBody) => {
        try {
            const response = await API.post(`${BASE_URL}/property/add-packing-list`,reqBody);
            return response;
        } catch (error) {
            return error.response;
        }
    },

    updatePackingList: async (reqBody) => {
        try {
            const response = await API.post(`${BASE_URL}/property/update-packing-list-by-id`,reqBody);
            return response;
        } catch (error) {
            return error.response;
        }
    },

    rulesList: async ()=>{
        try {
            const response = await API.get(`${BASE_URL}/property/camp-rules-list`);
            
            return response;
        } catch (error) {
            
            return error.response;
        }
    },

    
    addRules: async (reqBody) => {
        try {
            const response = await API.post(`${BASE_URL}/property/add-camp-rules-master`,reqBody);
            return response;
        } catch (error) {
            return error.response;
        }
    },

    updateRules: async (reqBody) => {
        try {
            const response = await API.post(`${BASE_URL}/property/update-rules-by-id`,reqBody);
            return response;
        } catch (error) {
            return error.response;
        }
    },

    policiesList: async ()=>{
        try {
            const response = await API.get(`${BASE_URL}/property/prop-cancellation-policy-list-master`);
            
            return response;
        } catch (error) {
            
            return error.response;
        }
    },

    
    addPolicies: async (reqBody) => {
        try {
            const response = await API.post(`${BASE_URL}/property/add-prop-cancellation-policy-master`,reqBody);
            return response;
        } catch (error) {
            return error.response;
        }
    },

    updatePolicies: async (reqBody) => {
        try {
            const response = await API.post(`${BASE_URL}/property/update-prop-cancellation-policy-by-id`,reqBody);
            return response;
        } catch (error) {
            return error.response;
        }
    },

    stayList: async ()=>{
        try {
            const response = await API.get(`${BASE_URL}/property/stay-detail-list`);
            
            return response;
        } catch (error) {
            
            return error.response;
        }
    },
    addOnList: async ()=>{
        try {
            const response = await API.get(`${BASE_URL}/property/add-on-list`);
            
            return response;
        } catch (error) {
            
            return error.response;
        }
    },

    eventsList: async ()=>{
        try {
            const response = await API.get(`${BASE_URL}/experience/event-list-for-admin`);
            
            return response;
        } catch (error) {
            
            return error.response;
        }
    },

    updateAddOns: async (reqBody) => {
        try {
            const response = await API.post(`${BASE_URL}/property/update-addons-by-id`,reqBody);
            return response;
        } catch (error) {
            return error.response;
        }
    },
    
    facilitesList: async ()=>{
        try {
            const response = await API.get(`${BASE_URL}/property/facilities-list`);
            return response;
        } catch (error) {
            return error.response;
        }
    },

    updateStayById: async (reqBody) => {
        try {
            const response = await API.post(`${BASE_URL}/property/update-stay-by-id`,reqBody);
            return response;
        } catch (error) {
            return error.response;
        }
    },
    
    carryList: async ()=>{
        try {
            const response = await API.get(`${BASE_URL}/experience/exp-carry-list`);
            return response;
        } catch (error) {
            return error.response;
        }
    },
    updateEventAndExpById: async (reqBody) => {
        try {
            const response = await API.post(`${BASE_URL}/experience/update-exp-and-event-by-id`,reqBody);
            return response;
        } catch (error) {
            return error.response;
        }
    },

    expListForAdmin: async ()=>{
        try {
            const response = await API.get(`${BASE_URL}/experience/exp-list-for-admin`);
            return response;
        } catch (error) {
            return error.response;
        }
    },

    updatePropertyById: async (reqBody, id) => {
        try {
            const response = await API.post(`${BASE_URL}/property/update/${id}`,reqBody);
            return response;
        } catch (error) {
            return error.response;
        }
    },
    
}

export default userServices