import { useEffect, useState } from "react";
import userServices from "../services/user.services";
import HostList from "../components/Host/HostList";
import PropertyDetail from "../components/Properties/PropertyDetailPage";
import { useNavigate, useParams } from "react-router-dom";

function HostDetail() {
  const [data, setData] = useState([])
  const [showPropertyDetail, setShowPropertyDetail] = useState(false)
  const { id } = useParams();
  const navigate = useNavigate();
  const getData = async () => {
    const result = await userServices.propertiesDetailByHost({hostId: id})
    if (result.status === 200) {
      setData(result.data.data)
    }
  }
  const onClickProperty = () => {
    setShowPropertyDetail((prevState) => prevState ? false : true)
}
  useEffect(() => {
    getData()
  }, [])
  return (
    <div className="page-inner">
      {/* Main Wrapper */}
      {showPropertyDetail === true ? 
      <PropertyDetail  setShowPropertyDetail={setShowPropertyDetail}/>
      :
      <div id="main-wrapper">
        <div className="pageheader pd-t-25 pd-b-35">
          {/* <div className="pd-t-5 pd-b-5">
            <h1 className="pd-0 mg-0 tx-24 text-overflow">Host Details</h1>
          </div> */}
           <div className="d-flex justify-content-between align-items-center pd-t-5 pd-b-5" >
            <h1 className="pd-0 mg-0 tx-20 text-overflow">Properties Details</h1>

            <button
              type="button "
              className="btn return-btn btn-sm"
              onClick={() => navigate('/')}
            >
              Return
            </button>
          </div>
        </div>
                 

        <div className="row row-xs clearfix">
          <div className="col-sm-6 col-xl-3">
            <div className="card mg-b-20">
              <div className="card-body pd-y-0">
                <div className="custom-fieldset mb-4">
                  <div className="clearfix">
                    <label>Total Properties</label>
                  </div>
                  <div className="d-flex align-items-center text-dark">
                    <div className="wd-40 wd-md-50 ht-40 ht-md-50 mg-r-10 mg-md-r-10 d-flex align-items-center justify-content-center rounded card-icon-success">
                      <img src="/assets/images/Properties.png" />
                    </div>
                    {/* <div onClick={onClickProperty}> */}
                    <div>
                      <h2 className="tx-20 tx-sm-18 tx-md-24 mb-0 mt-2 mt-sm-0 tx-normal tx-rubik tx-dark">
                        <span className="counter">{data?.length || '00'}</span>

                      </h2>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-sm-6 col-xl-3">
            <div className="card mg-b-20">
              <div className="card-body pd-y-0">
                <div className="custom-fieldset mb-4">
                  <div className="clearfix">
                    <label>Total Experiences</label>
                  </div>
                  <div className="d-flex align-items-center text-dark">
                    <div className="wd-40 wd-md-50 ht-40 ht-md-50 mg-r-10 mg-md-r-10 d-flex align-items-center justify-content-center rounded card-icon-success">
                    <img src="/assets/images/Experiences.png" />
                    </div>
                    <div>
                      <h2 className="tx-20 tx-sm-18 tx-md-24 mb-0 mt-2 mt-sm-0 tx-normal tx-rubik tx-dark">
                        <span className="counter">00</span>

                      </h2>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-sm-6 col-xl-3">
            <div className="card mg-b-20">
              <div className="card-body pd-y-0">
                <div className="custom-fieldset mb-4">
                  <div className="clearfix">
                    <label>Total Conveyance</label>
                  </div>
                  <div className="d-flex align-items-center text-dark">
                    <div className="wd-40 wd-md-50 ht-40 ht-md-50 mg-r-10 mg-md-r-10 d-flex align-items-center justify-content-center rounded card-icon-success">
                      <img src="/assets/images/Conveyance.png" />
                    </div>
                    <div>
                      <h2 className="tx-20 tx-sm-18 tx-md-24 mb-0 mt-2 mt-sm-0 tx-normal tx-rubik tx-dark">
                        <span className="counter">00</span>

                      </h2>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-sm-6 col-xl-3">
            <div className="card mg-b-20">
              <div className="card-body pd-y-0">
                <div className="custom-fieldset mb-4">
                  <div className="clearfix">
                    <label>Total Stay</label>
                  </div>
                  <div className="d-flex align-items-center text-dark">
                    <div className="wd-40 wd-md-50 ht-40 ht-md-50 mg-r-10 mg-md-r-10 d-flex align-items-center justify-content-center rounded card-icon-success">
                      <img src="/assets/images/Stay.png" />
                    </div>
                    <div>
                      <h2 className="tx-20 tx-sm-18 tx-md-24 mb-0 mt-2 mt-sm-0 tx-normal tx-rubik tx-dark">
                        <span className="counter">00</span>

                      </h2>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      
    }
      {/*/ Main Wrapper End */}
    </div>
  );
}

export default HostDetail;
