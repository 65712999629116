import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { toast } from 'react-toastify';
import { FieldSchema } from '../../schema/FieldSchema';
import TextError from '../../helpers/FormikHelper/TextError';
import userServices from '../../services/user.services';
import { BASE_URL } from '../../services/config';

const ExpSubTypeForm = ({ toggle, isAddMode, selectedRow, getData }) => {
    const [file, setFile] = useState(isAddMode === 'Add' ? '' : `${BASE_URL}/${selectedRow?.adventureImg}`);
    const [expTypes, setExpTypes] = useState([])
    function handleChange(e) {
        if (e.target.files.length > 0) {
            setFile(URL.createObjectURL(e.target.files[0]));
        } else {
            setFile('')
        }
    }

    const initialValues = {
        adventureName: '',
        adventureDescription: '',
        experienceTypeId:''
    }

    const savedValues = {
        adventureName: selectedRow?.adventureName,
        adventureDescription: selectedRow?.adventureDescription,
        experienceTypeId: selectedRow?.experienceTypeId
    }

    const onSubmit = async (values) => {

        if (isAddMode === 'Add') {
            let result = await userServices.addExpSubTypes(values);
            if (result.data.status === 'success') {
                setFile('')
                toggle()
                getData()
                toast.success('Data Added successfully', { toastId: 'registration_success', autoClose: 3000 });
            } else {
                toast.error(result.data.message, { toastId: 'registration_fail', autoClose: 3000 });
            }
        } else {
            values.id = selectedRow?._id
            const result = await userServices.updateExpSubTypes(values);
            if (result.data.status === 'success') {
                setFile('')
                toggle()
                getData()
                toast.success('Data Updated successfully', { toastId: 'update_success', autoClose: 3000 });
            } else {
                toast.error(result.data.message, { toastId: 'update_fail', autoClose: 3000 });

            }
        }
    }

    useEffect(() => {
        userServices.expTypeList().then((result) => {
            if (result.data.status === 'success') {
                setExpTypes(result.data.data)
            }
        })
    },[])
    return (
        <React.Fragment>
            <Formik
                initialValues={isAddMode === 'Add' ? initialValues : savedValues}
                // validationSchema={FieldSchema(`${isAddMode === 'Add' ? 'ADD_EXP_SUB' : 'ADD_EXP_SUB'}`)}
                onSubmit={onSubmit}
                enableReinitialize
            >
                {({ setFieldValue, values }) => (<Form>
                    <ModalHeader toggle={toggle} className='border-0 pb-0'>
                        <div className="card-title d-flex align-items-center mb-0">
                            <h5 className="mb-0 text-primary">{isAddMode === 'Add' ? 'Add Exp Sub Types' : 'Update Exp Sub Types'}</h5>
                        </div>
                    </ModalHeader>
                    <ModalBody className="">
                        <div className="row" >
                            <div className="col-md-12 mb-3">
                                <label htmlFor="experienceTypeId" className="form-label">
                                    Exp Type
                                </label>
                                <Field as='select' className='form-select' id="experienceTypeId" name="experienceTypeId" >
                                    <option disabled value="">Select Exp Types</option>

                                    {
                                        expTypes.map((e, key) => {
                                            return <option key={key} value={e._id}>{e.experienceName}</option>;
                                        })
                                    }
                                </Field>
                                <ErrorMessage name="experienceTypeId" component={TextError} />
                            </div>
                            <div className="col-12 mb-3">
                                <label htmlFor="adventureName" className="form-label">
                                    Exp Sub Type
                                </label>
                                <Field type="text" className="form-control" id="adventureName" name='adventureName' />
                                <ErrorMessage name='adventureName' component={TextError} />
                            </div>
                            <div className="col-12 mb-3">
                                <label htmlFor="adventureDescription" className="form-label">
                                    Description
                                </label>
                                <Field type="adventureDescription" className="form-control" id="adventureDescription" name='adventureDescription'
                                />
                                <ErrorMessage name='adventureDescription' component={TextError} />
                            </div>
                            <div className="col-12 mb-3">
                                <label htmlFor="file" className="form-label">
                                    image/icon
                                </label>

                                <input id="file" name="file" type="file" onChange={(event) => {
                                    setFieldValue("file", event.currentTarget.files[0]);
                                    handleChange(event)
                                }} className="form-control" />
                                {file === undefined || file === '' ? '' : <img src={file}
                                    // alt={selectedRow?.terrainImg}
                                    className="img-thumbnail mt-2"
                                    height={150}
                                    width={150} />}

                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter className='border-0 pt-0'>
                        <button
                            type="button"
                            className="btn btn-secondary btn-sm"
                            onClick={() => {
                                toggle();
                                setFile('')
                            }}
                        >
                            Close
                        </button>
                        <button type="submit" className="btn btn-primary btn-sm">
                            {isAddMode === "Add" ? `Save` : `Update`}
                        </button>
                    </ModalFooter>
                </Form>
                )}
            </Formik>
        </React.Fragment>
    );
};

export default ExpSubTypeForm;