import React from 'react';
import { Field, ErrorMessage, useFormikContext } from 'formik';

const IncludeSelect = ({ includes }) => {
  const { setFieldValue, values } = useFormikContext();

  const handleSelectChange = (event) => {
    const selectedValues = Array.from(event.target.selectedOptions, (option) => option.value);
    setFieldValue('includes', selectedValues); // Correctly update Formik's state with selected values
  };

  return (
    <div className="col-6 mb-3">
      <label htmlFor="includes" className="form-label">
      Select Inclusions
      </label>
      <Field
        as="select"
        className="form-select"
        id="includes"
        name="includes"
        multiple
        value={values.includes} // Bind the value to Formik's state
        onChange={handleSelectChange}
      >
        <option disabled value="">
          Select Inclusions
        </option>
        {includes.map((e, key) => (
          <option key={key} value={e._id}>
            {e.includesName}
          </option>
        ))}
      </Field>
      <ErrorMessage name="includes" component="div" className="text-danger" />
    </div>
  );
};

export default IncludeSelect;
