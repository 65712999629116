import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const SideBar = ({ menuIconOnClick }) => {
  const [isActive, setIsActive] = useState("");
  const [activeParentMenu, setActiveParentMenu] = useState('')
  const onClick = (value) => {
    setIsActive(value);
  };

  const onClickParentMenu = (value) => {
    // setActiveParentMenu(value)
    setActiveParentMenu(prevActiveParentMenu => prevActiveParentMenu === value ? '' : value);
  }
  useEffect(() => {
    setIsActive(window.location.pathname);
  }, [isActive]);

  return (
    <div className="page-sidebar">
      <div className="logo">
        <a href className="logo-img">
          <img
            className="desktop-logo"
            src="logo.svg"
            alt=""
          />
          <img
            className="small-logo"
            src="logo.svg"
            alt=""
          />
        </a>
        <i
          className="ion-ios-close-empty"
          id="sidebar-toggle-button-close"
          onClick={menuIconOnClick}
        />
      </div>

      <div className="page-sidebar-inner">
        <div className="page-sidebar-menu">
          <ul className="accordion-menu">
            <li
              className={isActive === "/" || isActive === "/" ? "active" : ""}
            >
              <Link to="/" onClick={() => { onClick(); onClickParentMenu('/'); }}>
                <i className="fa fa-users"></i>
                <span>Host</span>
              </Link>
            </li>

            {/* <li
              className={isActive === "/properties" || isActive === "/properties" ? "active" : ""}
            >
              <Link to="/properties" onClick={onClick}>
                <i className="fa fa-users"></i>
                <span>Properties</span>
              </Link>
            </li> */}

            {/* <li className={activeParentMenu === `p_property` ? 'open active' : ''}>
              <a href onClick={() => onClickParentMenu('p_property')} style={{ cursor: 'pointer' }}>
                <i data-feather="home" />
                <span>Properties</span>
                <i className="accordion-icon fa fa-angle-left" />
              </a>
              <ul className="sub-menu" style={{ display: activeParentMenu === `p_property` ? "block" : "none" }}>

                <li
                  className={isActive === "/properties" || isActive === "/properties" ? "active" : ""}
                >
                  <Link to="/properties" onClick={onClick}>

                    <span>Active Properties</span>
                  </Link>
                </li>

                <li
                  className={isActive === "/inactive_properties" || isActive === "/inactive_properties" ? "active" : ""}
                >
                  <Link to="/inactive_properties" onClick={onClick}>

                    <span>Published Properties</span>
                  </Link>
                </li>



              </ul>
            </li> */}

            {/* <li className={activeParentMenu === `p_experience` ? 'open active' : ''}>
              <a href onClick={() => onClickParentMenu('p_experience')} style={{ cursor: 'pointer' }}>
                <i data-feather="home" />
                <span>Experience</span>
                <i className="accordion-icon fa fa-angle-left" />
              </a>
              <ul className="sub-menu" style={{ display: activeParentMenu === `p_experience` ? "block" : "none" }}>

                <li
                  className={isActive === "/experience" || isActive === "/experience" ? "active" : ""}
                >
                  <Link to="/experience" onClick={onClick}>

                    <span>Active Experience</span>
                  </Link>
                </li>

                <li
                  className={isActive === "/unapprove-experience" || isActive === "/unapprove-experience" ? "active" : ""}
                >
                  <Link to="/unapprove-experience" onClick={onClick}>

                    <span>Published Experience</span>
                  </Link>
                </li>



              </ul>
            </li> */}
            <li className={isActive === "/properties" || isActive === "/properties" ? "active" : ""}>
              <Link to="/properties" onClick={() => { onClick(); onClickParentMenu('/properties'); }}>
                <i className="fa fa-users"></i>
                <span>PROPERTIES</span>
              </Link>
            </li>
            <li className={isActive === "/stays" || isActive === "/stays" ? "active" : ""}>
              <Link to="/stays" onClick={() => { onClick(); onClickParentMenu('/stays'); }}>
                <i className="fa fa-users"></i>
                <span>STAYS</span>
              </Link>
            </li>
            <li className={isActive === "/add-ons" || isActive === "/add-ons" ? "active" : ""}>
              <Link to="/add-ons" onClick={() => { onClick(); onClickParentMenu('/add-ons'); }}>
                <i className="fa fa-users"></i>
                <span>ADD ONS</span>
              </Link>
            </li>
            <li className={isActive === "/experience" || isActive === "/experience" ? "active" : ""}>
              <Link to="/experience" onClick={() => { onClick(); onClickParentMenu('/experience'); }}>
                <i className="fa fa-users"></i>
                <span>EXPERIENCE</span>
              </Link>
            </li>
            <li className={isActive === "/events" || isActive === "/events" ? "active" : ""}>
              <Link to="/events" onClick={() => { onClick(); onClickParentMenu('/events'); }}>
                <i className="fa fa-users"></i>
                <span>EVENTS</span>
              </Link>
            </li>
            <li className={isActive === "/bookings" || isActive === "/bookings" ? "active" : ""}>
              <Link to="/bookings" onClick={() => { onClick(); onClickParentMenu('/bookings'); }}>
                <i className="fa fa-users"></i>
                <span>BOOKING</span>
              </Link>
            </li>

            {/* <li className={activeParentMenu === `p_booking` ? 'open active' : ''}>
              <a href onClick={() => onClickParentMenu('p_booking')} style={{ cursor: 'pointer' }}>
                <i data-feather="home" />
                <span>Bookings</span>
                <i className="accordion-icon fa fa-angle-left" />
              </a>
              <ul className="sub-menu" style={{ display: activeParentMenu === `p_booking` ? "block" : "none" }}>

                <li
                  className={isActive === "/bookings" || isActive === "/bookings" ? "active" : ""}
                >
                  <Link to="/bookings" onClick={onClick}>

                    <span>Bookings</span>
                  </Link>
                </li>



              </ul>
            </li> */}





            {/* <li className={activeParentMenu===`p_conveyance` ? 'open active': ''}>
                        <a href onClick={()=>onClickParentMenu('p_conveyance')} style={{ cursor: 'pointer' }}>
                            <i data-feather="home" />
                            <span>Conveyance</span>
                            <i className="accordion-icon fa fa-angle-left" />
                        </a>
                        <ul className="sub-menu" style={{ display: activeParentMenu===`p_conveyance`?"block":"none" }}>
                          
                        <li
                            className={isActive === "/active-conveyance" || isActive === "/active-conveyance" ? "active" : ""}
                          >
                            <Link to="/active-conveyance" onClick={onClick}>
                             
                              <span>Active Conveyance</span>
                            </Link>
                          </li> 

                          <li
                            className={isActive === "/published-conveyance" || isActive === "/published-conveyance" ? "active" : ""}
                          >
                            <Link to="/published-conveyance" onClick={onClick}>
                             
                              <span>Published Conveyance</span>
                            </Link>
                          </li> 
                       
                           
                        
                        </ul>
            </li> */}

            <li className={activeParentMenu === `master-data` ? 'open active' : ''}>
              <a href onClick={() => onClickParentMenu('master-data')} style={{ cursor: 'pointer' }}>
                <i data-feather="home" />
                <span>MASTER DATA</span>
                <i className="accordion-icon fa fa-angle-left" />
              </a>
              <ul className="sub-menu" style={{ display: activeParentMenu === `master-data` ? "block" : "none" }}>

                <li
                  className={isActive === "/languages" ? "active" : ""}
                >
                  <Link to="/languages" onClick={onClick}>

                    <span>LANGUAGES</span>
                  </Link>
                </li>

                <li
                  className={isActive === "/seasons" ? "active" : ""}
                >
                  <Link to="/seasons" onClick={onClick}>

                    <span>SEASONS</span>
                  </Link>
                </li>

                <li
                  className={isActive === "/terrian" ? "active" : ""}
                >
                  <Link to="/terrian" onClick={onClick}>

                    <span>TERRAIN</span>
                  </Link>
                </li>

                <li
                  className={isActive === "/amenities" ? "active" : ""}
                >
                  <Link to="/amenities" onClick={onClick}>

                    <span>AMENITIES</span>
                  </Link>
                </li>
                <li
                  className={isActive === "/stay-types" ? "active" : ""}
                >
                  <Link to="/stay-types" onClick={onClick}>

                    <span>STAY TYPES</span>
                  </Link>
                </li>

                <li
                  className={isActive === "/exp-types" ? "active" : ""}
                >
                  <Link to="/exp-types" onClick={onClick}>

                    <span>EXP TYPES</span>
                  </Link>
                </li>
                <li
                  className={isActive === "/exp-sub-types" ? "active" : ""}
                >
                  <Link to="/exp-sub-types" onClick={onClick}>

                    <span>EXP SUB TYPES</span>
                  </Link>
                </li>
                <li className={isActive === "/exp-includes" ? "active" : ""}>
                  <Link to="/exp-includes" onClick={onClick}>
                    <span>EXPERIENCE/EVENTS INCLUSION</span>
                  </Link>
                </li>
                <li className={isActive === "/food" ? "active" : ""}>
                  <Link to="/food" onClick={onClick}>
                    <span>Food</span>
                  </Link>
                </li>
                <li className={isActive === "/transportation-mode" ? "active" : ""}>
                  <Link to="/transportation-mode" onClick={onClick}>
                    <span>TRANSPORTATION MODE</span>
                  </Link>
                </li>
                <li className={isActive === "/add-on-types" ? "active" : ""}>
                  <Link to="/add-on-types" onClick={onClick}>
                    <span>ADD ON TYPES</span>
                  </Link>
                </li>

                <li className={isActive === "/guest-packing-list" ? "active" : ""}>
                  <Link to="/guest-packing-list" onClick={onClick}>
                    <span>PACKING LIST</span>
                  </Link>
                </li>
                <li className={isActive === "/rules" ? "active" : ""}>
                  <Link to="/rules" onClick={onClick}>
                    <span>RULES</span>
                  </Link>
                </li>
                <li className={isActive === "/policies" ? "active" : ""}>
                  <Link to="/policies" onClick={onClick}>
                    <span>POLICIES</span>
                  </Link>
                </li>

              </ul>
            </li>

          </ul>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
