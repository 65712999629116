import { useEffect, useState } from "react";
import userServices from "../services/user.services";
import ExperienceList from "../components/Experience/ExperienceList";
import UnApprovedExperienceList from "../components/Experience/UnApprovedExperienceList";
import ConfirmationModal from "../components/CommonComponents/ConfirmationModal";
import { toast } from "react-toastify";
import ExperienceDetail from "../components/Experience/ExperienceDetailPage";

function UnApprovedExperience() {
  const [data, setData] = useState([])
  const [confirmModal, setConfirmModal] = useState(false);
  const [showDetail, setShowDetail] = useState(false)
  const [selectedRow, setSelectedRow] = useState('')

  const toggleConfirm = () => setConfirmModal(!confirmModal);

  const getData = async () => {
    const result = await userServices.unApprovedExperienceList()
    if (result.status === 200) {
      setData(result.data.data)
    }
  }


  const approvedExperience =()=>{
    userServices.approveExperience({id: "selectedId"}).then((res)=>{
      if(res.status===200){
        toast.success("Experience approved successfully", {
          toastId: "update_success",
          autoClose: 3000,
        });
        getData()
        toggleConfirm()
      }
    })
  }

  useEffect(() => {
    getData()
  }, [])
  return (
    <div className="page-inner">
      {showDetail===false ?    <div id="main-wrapper">
        <div className="pageheader pd-t-25 pd-b-35">
          <div className="pd-t-5 pd-b-5">
            <h1 className="pd-0 mg-0 tx-24 text-overflow">Published Experience</h1>
          </div>
        </div>

        <div className="row row-xs clearfix">
          <div className="col-md-12 col-lg-12">
            <div className="card mg-b-20">
              <div className="card-header">
                {/* <h4 className="card-header-title">Users List</h4> */}
                <div className="card-header-btn">
                  <a
                    href
                    data-toggle="collapse"
                    className="btn card-collapse"
                    data-target="#collapse1"
                    aria-expanded="true"
                  >
                    <i className="ion-ios-arrow-down"></i>
                  </a>
                </div>

                <div className="btn-group"></div>
              </div>

              <div className="card-body collapse show" id="collapse1">

                <UnApprovedExperienceList
                  users={data}
                  setShowDetail={setShowDetail}
                  setSelectedRow={setSelectedRow}

                />
              </div>

              {/* <ConfirmationModal 
              toggle={toggleConfirm} 
              modal={confirmModal} 
              onClick={approvedExperience} 
              title="Confirm Approved"
              text="Are you sure you want to approve this property?"
              iconType='mdi-alert-circle'
            /> */}
            </div>
          </div>
        </div>
      </div>
      :
      <ExperienceDetail setShowDetail={setShowDetail} selectedRow={selectedRow} setSelectedRow={setSelectedRow} getData={getData}/>
     }
    </div>
  );
}

export default UnApprovedExperience;
