import { useEffect, useState } from "react";
// import ExperienceDetail from "../components/Experience/ExperienceDetailPage";
import BookingList from "../../components/Bookings/BookingList";
import userServices from "../../services/user.services";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import BookingForm from "../../components/Bookings/BookingForm";
function Bookings() {
  const [data, setData] = useState([])
  const [showDetail, setShowDetail] = useState(false)
  const [selectedRow, setSelectedRow] = useState('')
  const [modal, setModal] = useState(false);
  const [modalAdd, setModalAdd] = useState(false);
  const [refundAmount, setRefundAmount] = useState(0)
  const [isAddMode, setIsAddMode] = useState('Add');

  const toggle = () => setModal(!modal);
  const toggleAdd = () => setModalAdd(!modalAdd);
  const getData = async () => {
    const result = await userServices.tripBookingList()
    if (result.status === 200) {
      setData(result.data.data)
    }
  }

  const onClickRefund = () => {
    const reqBody = {
      tripBookingId: selectedRow._id,
      refundAmount: Number(refundAmount)
    }
    userServices.refundPayment(reqBody).then((result) => {
      if (result.status === 200) {
        toggle()
      }
    }).catch((error) => console.log('error', error))
  }

  useEffect(() => {
    getData()
  }, [])
  return (
    <div className="page-inner">
      {showDetail === false ? <div id="main-wrapper">
        <div className="pageheader pd-t-25 pd-b-35">
          <div className="pd-t-5 pd-b-5">
            <h1 className="pd-0 mg-0 tx-24 text-overflow">Bookings</h1>
          </div>
        </div>

        <div className="row row-xs clearfix">
          <div className="col-md-12 col-lg-12">
            <div className="card mg-b-20">
              <div className="card-header">
                {/* <h4 className="card-header-title">Users List</h4> */}
                <div className="card-header-btn">
                  <a
                    href
                    data-toggle="collapse"
                    className="btn card-collapse"
                    data-target="#collapse1"
                    aria-expanded="true"
                  >
                    <i className="ion-ios-arrow-down"></i>
                  </a>
                </div>

                <div className="btn-group"></div>
              </div>

              <div className="card-body collapse show" id="collapse1">

                <BookingList
                  users={data}
                  setShowDetail={setShowDetail}
                  setSelectedRow={setSelectedRow}
                  toggle={toggle}
                  setIsAddMode={setIsAddMode}
                  toggleAdd={toggleAdd}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
        : ''
        //  <ExperienceDetail setShowDetail={setShowDetail} selectedRow={selectedRow} setSelectedRow={setSelectedRow} getData={getData}/>
      }

      <Modal isOpen={modal} toggle={toggle} size="sm" centered={true} className="">
        <ModalHeader className='pb-2' toggle={toggle}>Refund </ModalHeader>
        <ModalBody className='editModal'>
          <div className="col-12 mb-3">
            <label htmlFor="date" className="form-label">
              Please enter refundable  amount
            </label>
            <input type='text' id='amt' className='form-control mb-0' onChange={(e) => { setRefundAmount(e.target.value) }} />
          </div>

        </ModalBody>
        <ModalFooter className='justify-content-between'>
          <Button className='btn return-btn btn-sm' onClick={() => onClickRefund()}>
            Refund Payment
          </Button>{' '}
          <Button className='btn  btn-sm' onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modalAdd} toggle={toggleAdd} size="lg" centered={true} className="">
        <BookingForm toggle={toggleAdd} isAddMode={isAddMode} selectedRow={selectedRow} getData={getData} />

      </Modal>
    </div>
  );
}

export default Bookings;
