import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { toast } from 'react-toastify';
import TextError from '../../helpers/FormikHelper/TextError';
import userServices from '../../services/user.services';
import { FieldSchema } from '../../schema/FieldSchema';

const BookingForm = ({ toggle, isAddMode, selectedRow, getData }) => {
    const [stayTypes, setStayTypes] = useState([])
    const [foods, setFoods] = useState([])
    const [facilities, setFacilites] = useState([])

    const initialValues = {
        serviceName: '',
    }

    const savedValues = {
        primaryguestName: selectedRow?.primaryguestName,
        primaryguestEmail: selectedRow?.primaryguestEmail,
        countAdults: selectedRow?.countAdults,
        primaryguestPhone: selectedRow?.primaryguestPhone,

    }

    const onSubmit = async (values) => {

        if (isAddMode === 'Add') {
            let result = await userServices.addPolicies(values);
            if (result.data.status === 'success') {

                toggle()
                getData()
                toast.success('Data Added successfully', { toastId: 'registration_success', autoClose: 3000 });
            } else {
                toast.error(result.data.message, { toastId: 'registration_fail', autoClose: 3000 });
            }
        } else {
            values.id = selectedRow?._id
            const result = await userServices.updateStayById(values);
            if (result.data.status === 'success') {

                toggle()
                getData()
                toast.success('Data Updated successfully', { toastId: 'update_success', autoClose: 3000 });
            } else {
                toast.error(result.data.message, { toastId: 'update_fail', autoClose: 3000 });

            }
        }
    }

    useEffect(() => {
        userServices.stayTypeList().then((result) => {
            if (result.data.status === 'success') {
                setStayTypes(result.data.data)
            }
        })

        userServices.foodList().then((result) => {
            if (result.data.status === 'success') {
                setFoods(result.data.data)
            }
        })

        userServices.facilitesList().then((result) => {
            if (result.data.status === 'success') {
                setFacilites(result.data.data)
            }
        })
    }, [])

    return (
        <React.Fragment>
            <Formik
                initialValues={isAddMode === 'Add' ? initialValues : savedValues}
                validationSchema={FieldSchema(`${isAddMode === 'Add' ? 'ADD_STAY_DETAIL' : 'ADD_STAY_DETAIL'}`)}
                onSubmit={onSubmit}
                enableReinitialize
            >
                {({ setFieldValue, values }) => (<Form>
                    <ModalHeader toggle={toggle} className='border-0 pb-0'>
                        <div className="card-title d-flex align-items-center mb-0">
                            <h5 className="mb-0 text-primary">{isAddMode === 'Add' ? 'Add Stay' : 'Booking'}</h5>
                        </div>
                    </ModalHeader>
                    <ModalBody className="">
                        <div className="row" >
                            <div className="col-12 mb-3">
                                <label htmlFor="primaryguestName" className="form-label">
                                    Primary Guest Name
                                </label>
                                <Field type="text" className="form-control" id="rule" name='primaryguestName' disabled />
                                <ErrorMessage name='primaryguestName' component={TextError} />
                            </div>

                            <div className="col-12 mb-3">
                                <label htmlFor="primaryguestEmail" className="form-label">
                                Primary Guest Email
                                </label>
                                <Field type="text" className="form-control" id="rule" name='primaryguestEmail' disabled />
                                <ErrorMessage name='primaryguestEmail' component={TextError} />
                            </div>
                            <div className="col-12 mb-3">
                                <label htmlFor="primaryguestPhone" className="form-label">
                                Primary Guest Phone
                                </label>
                                <Field type="text" className="form-control" id="rule" name='primaryguestPhone' disabled />
                                <ErrorMessage name='primaryguestPhone' component={TextError} />
                            </div>
                            <div className="col-12 mb-3">
                                <label htmlFor="countAdults" className="form-label">
                                Number of adults
                                </label>
                                <Field type="text" className="form-control" id="rule" name='countAdults' disabled />
                                <ErrorMessage name='countAdults' component={TextError} />
                            </div>

                            {/* <div className="col-md-6 mb-3">
                                <label htmlFor="status" className="form-label">
                                    Status
                                </label>
                                <Field as='select' className='form-select' id="status" name="status" >
                                    <option value={0}>INACTIVE</option>
                                    <option value={1}>ACTIVE</option>


                                </Field>
                                <ErrorMessage name="status" component={TextError} />
                            </div> */}

                        </div>
                    </ModalBody>
                    <ModalFooter className='border-0 pt-0'>
                        <button
                            type="button"
                            className="btn btn-secondary btn-sm"
                            onClick={() => {
                                toggle();
                            }}
                        >
                            Close
                        </button>
                        {/* <button type="submit" className="btn btn-primary btn-sm">
                            {isAddMode === "Add" ? `Save` : `Update`}
                        </button> */}
                    </ModalFooter>
                </Form>
                )}
            </Formik>
        </React.Fragment>
    );
};

export default BookingForm;