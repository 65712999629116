import axios from "axios";

const API = axios;
// const BASE_URL='http://localhost:4014';
// const BASE_URL='http://192.168.1.122:4013'
const BASE_URL='https://api.furutcamps.com:4014'
// const BASE_URL ='http://103.15.67.180:4014'

export  {
    API,
    BASE_URL
}