import { useEffect, useState } from "react";
import userServices from "../../services/user.services";
import ConfirmationModal from "../CommonComponents/ConfirmationModal";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function PropertyDetail({ setShowPropertyDetail, etPropertyId, propertyId, propertyData }) {
  const [data, setData] = useState([])
  const [seasionData, setSeasionData] = useState([])
  const [terrainData, setTerrianData] = useState([])
  const [amenitiesData, setAmenitiesData] = useState([])
  const [stayTypeDetail, setStayTypeDetail] = useState([])
  const [foodDetail, setFoodDetail] = useState([])
  const [confirmModal, setConfirmModal] = useState(false);
  const navigate = useNavigate();

  const toggleConfirm = () => setConfirmModal(!confirmModal);

  const getData = async () => {
    const result = await userServices.propertiesDetail({ id: propertyId })
    if (result.status === 200) {
      setData(result.data.data)
      setSeasionData(result.data.data.seasonData)
      setTerrianData(result.data.data.terrainData)
      setAmenitiesData(result.data.data?.amenities||[])
      setStayTypeDetail(result.data.data.stayTypeDetail)
    
    }
  }

const approvedProperty =()=>{
  userServices.approveProperty({id:propertyId}).then((res)=>{
    if(res.status===200){
      toast.success("Property approved successfully", {
        toastId: "update_success",
        autoClose: 3000,
      });
      propertyData()
      setShowPropertyDetail(false);
      etPropertyId('');
      toggleConfirm()
    }
  })
}
  useEffect(() => {
    getData()
  }, [])
  return (
    <div className="page-inner">
      {/* Main Wrapper */}
      <div id="main-wrapper">
        <div className="pageheader pd-t-25 pd-b-35 ">
          <div className="d-flex justify-content-between align-items-center pd-t-5 pd-b-5" >
            <h1 className="pd-0 mg-0 tx-20 text-overflow">Properties Details</h1>

            <button
              type="button "
              className="btn return-btn btn-sm"
              onClick={() => { setShowPropertyDetail(false); etPropertyId('') }}
            >
              Return
            </button>
          </div>
        </div>

        <div className="row clearfix">

          <div className="col-12">
            <div className="card p-md-4 p-3">
              <div className="row">
                <div className="col-sm-12 mb-3">
                  <div className="card mg-b-20">
                    <div className="card-body p-md-4 p-3 property-info">
                      <div className="card-label">Property Information</div>
                      <div className="d-flex justify-content-start align-items-baseline w-100 mb-2">
                        <h5>Property Name </h5>
                        <p>: {data?.propertyName || 'Sonkupi Banjara Camp'} </p>
                      </div>

                      <div className="d-flex justify-content-start align-items-baseline w-100 mb-2">
                        <h5>Property Located</h5>
                        <p>: {data?.propertyLocated?.fullAddress || 'Sonkupi Banjara Camp, Sonkupi. Baghmundi'}</p>
                      </div>

                      <div className="d-flex justify-content-start align-items-baseline w-100 mb-2">
                        <h5>Best Season</h5>
                        <p>:
                        {seasionData.map((item) => {
                          return (
                               <> <span className="badge badge-success">{item?.seasonName || 'Mountain'}</span> {" "}</>
                              );
                            })}
                            </p>
                        {/* <span class="badge badge-success">Summer (APR-JUN)</span> <span class="badge badge-success">Winter (DEC-JAN)</span> <span class="badge badge-success">Spring (FEB-MAR)</span> <span class="badge badge-success">Autumn (SEP-NOV)</span></p> */}
                      </div>

                      <div className="d-flex justify-content-start align-items-baseline w-100 mb-2">
                        <h5>Terrain</h5>
                        <p>: 
                        {terrainData.map((item) => {
                          return (
                              <> <span className="badge badge-success">{item?.terrainName || 'Valley'}</span> {" "}</>
                            );
                          })}
                          </p>
                        {/* <p>: <span class="badge badge-success">Mountain</span> <span class="badge badge-success">Valley</span></p> */}
                      </div>

                      <div className="d-flex justify-content-start align-items-baseline w-100 mb-2">
                        <h5>Amenities</h5>
                        <p>:
                        {amenitiesData.map((item) => {
                          return (
                              <> <span className="badge badge-success">{item?.amenitiesName || 'Parking'}</span> {" "}</>
                            );
                          })}
                          </p>
                        {/* <p>: <span class="badge badge-success">Wifi</span> <span class="badge badge-success">Parking</span> <span class="badge badge-success">Food & Bevarages</span></p> */}
                      </div>

                      <div className="d-flex justify-content-start align-items-baseline w-100 mb-2">
                        <h5>Check In & Check Out</h5>
                        <p>: <span class="badge badge-success">{data?.checkInCheckOutData?.checkInTime || '00:00'}</span> - <span class="badge badge-success">{data?.checkInCheckOutData?.checkOutTime || '00:00'}</span></p>
                      </div>
                    </div>
                  </div>
                </div>

                {stayTypeDetail.map((item) => {
                  return (
                    <div className="col-sm-12 mb-3">
                      <div className="card mg-b-20">
                        <div className="card-body p-md-4 p-3 property-info">
                          <div className="card-label">Stay Type - {item?.stayType || 'Stay Type'}</div>
                          <div className="d-flex justify-content-start align-items-baseline w-100 mb-2">
                            <h5>Number of Available</h5>
                            <p>: {item?.countAvailable}</p>
                          </div>

                          <div className="d-flex justify-content-start align-items-baseline w-100 mb-2">
                            <h5>Capacity Each</h5>
                            <p>: {item?.countCapacity }</p>
                          </div>

                          <div className="d-flex justify-content-start align-items-baseline w-100 mb-2">
                            <h5>Price</h5>
                            <p className="d-flex">
                              <div className="card p-2">Regular Price (₹) <div className="badge badge-success mt-1">{item?.priceRegular || '00.00'}</div></div>
                              <div className="card p-2 ml-2">Weekend Price (₹) <div className="badge badge-success mt-1">{item?.priceWeekend || '00.00'}</div></div>
                            </p>
                          </div>

                          <div className="d-flex justify-content-start align-items-baseline w-100 mb-2">
                            <h5>Food <span>(included in price)</span> </h5>
                            <p >:
                            {item?.includedFoodData.map((foodItem) => {
                           
                            return (
                               <> <span className="badge badge-success">{foodItem?.foodName || 'Wifi'} </span>{" "}</>
                            );
                          })}
                          </p>
                            {/* <p>: <span class="badge badge-success">Welcome Drink</span> <span class="badge badge-success">Lunch</span> <span class="badge badge-success">Dinner</span> <span class="badge badge-success">Breakfast</span></p> */}
                          </div>

                          <div className="d-flex justify-content-start align-items-baseline w-100 mb-2">
                            <h5>Facilities <span>(included in price)</span> </h5>
                           
                            {/* <p>: <span class="badge badge-success">Air-conditioner</span> <span class="badge badge-success">Air-cooler</span></p> */}
                            <p >:
                            {item?.includedFacilitiesFreeData.map((facilitesData) => {
                          
                            return (
                               <> <span className="badge badge-success">{facilitesData?.facilitiesName || 'Wifi'} </span>{" "}</>
                            );
                          })}
                          </p>
                          </div>

                          <div className="d-flex justify-content-start align-items-baseline w-100 mb-2">
                            <h5>Facilities <span>(Paid)</span> </h5>
                            {/* <p>: <span class="badge badge-success">Shower</span> <span class="badge badge-success">Hot Water</span></p> */}
                            <p>:
                            {item?.includedFacilitiesPaidData.map((facilitesPaid) => {
                            return (
                               <> <span className="badge badge-success">{facilitesPaid?.facilitiesName || 'Wifi'} </span>{" "}</>
                            );
                          })}
                          </p>
                          </div>

                        </div>
                      </div>
                    </div>
                  )
                })}


                <div className="col-sm-12 mb-3">
                  <div className="card mg-b-20">
                    <div className="card-body p-md-4 p-3 property-info">
                      <div className="card-label">Rules & Policies</div>

                      <div className="d-flex justify-content-start align-items-baseline w-100 mb-2">
                        <h5>Camp Rules</h5>
                        <p>: <span className="badge badge-warning">Eco Frindly</span>  <span className="badge badge-warning">Easy</span> <span className="badge badge-warning">Custom</span> </p>
                      </div>

                      <div className="d-flex justify-content-start align-items-baseline w-100 mb-2">
                        <h5>Cancellation Policies</h5>
                        <p>: <span className="badge badge-warning">Flexi</span> <span className="badge badge-warning">Custom</span></p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 col-sm-12 mb-3">
                  <div className="card mg-b-20">
                    <div className="card-body p-md-4 p-3 property-info">
                      <div className="card-label">Distance From</div>

                      <div className="distance-from">
                        <img src="/assets/images/airport.png" />
                        <span>{data?.distanceFrom?.airport || ''} {data?.distanceFrom?.distanceAirport || '0'} km</span>
                      </div>

                      <div className="distance-from">
                        <img src="/assets/images/railway.png" />
                        {/* <span>New Jalpaiguri 40 km</span> */}
                        <span>{data?.distanceFrom?.railwayStation || ''} {data?.distanceFrom?.distanceRailwayStation || '0'} km</span>
                      </div>

                      <div className="distance-from">
                        <img src="/assets/images/bus.png" />
                        {/* <span>Siliguri 30 km</span> */}
                        <span>{data?.distanceFrom?.busTaxiStand || ''} {data?.distanceFrom?.distanceBusTaxiStand || '0'} km</span>
                      </div>
                    </div>
                  </div>
                </div>

            {data?.propertyStatus===1 &&    <div className="col-12">
                <button type="button" onClick={toggleConfirm} class="btn return-btn btn-sm">Approve</button>
                </div>
                }

              </div>
            </div>
          </div>


          <ConfirmationModal 
              toggle={toggleConfirm} 
              modal={confirmModal} 
              onClick={approvedProperty} 
              title="Confirm Approved"
              text="Are you sure you want to approve this property?"
              iconType='mdi-alert-circle'
            />
        </div>
      </div>
      {/*/ Main Wrapper End */}
    </div>
  );
}

export default PropertyDetail;
