import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { toast } from 'react-toastify';
import { FieldSchema } from '../../schema/FieldSchema';
import TextError from '../../helpers/FormikHelper/TextError';
import userServices from '../../services/user.services';
import { BASE_URL } from '../../services/config';

const TransporationModeForm = ({ toggle, isAddMode, selectedRow, getData }) => {
    const [file, setFile] = useState(isAddMode === 'Add' ? '': `${BASE_URL}/${selectedRow?.img}` );
    function handleChange(e) {
        if (e.target.files.length > 0) {
            setFile(URL.createObjectURL(e.target.files[0]));
        } else {
            setFile('')
        }
    }

    const initialValues = {
        name: '',
        description: '',
        // default: false,
    }

    const savedValues = {
        name: selectedRow?.name,
        description: selectedRow?.description,
        // default: selectedRow?.default,
    }

    const onSubmit = async (values) => {

        if (isAddMode === 'Add') {
            let result = await userServices.addTransporationMode(values);
            if (result.data.status === 'success') {
                setFile('')
                toggle()
                getData()
                toast.success('Data Added successfully', { toastId: 'registration_success', autoClose: 3000 });
            } else {
                toast.error(result.data.message, { toastId: 'registration_fail', autoClose: 3000 });
            }
        } else {
            values.id = selectedRow?._id
            const result = await userServices.updateTransporationMode(values);
            if (result.data.status === 'success') {
                setFile('')
                toggle()
                getData()
                toast.success('Data Updated successfully', { toastId: 'update_success', autoClose: 3000 });
            } else {
                toast.error(result.data.message, { toastId: 'update_fail', autoClose: 3000 });

            }
        }
    }

    return (
        <React.Fragment>
            <Formik
                initialValues={isAddMode === 'Add' ? initialValues : savedValues}
                validationSchema={FieldSchema(`${isAddMode === 'Add' ? 'ADD_TRANSPORTATION' : 'ADD_TRANSPORTATION'}`)}
                onSubmit={onSubmit}
                enableReinitialize
            >
                {({ setFieldValue, values }) => (<Form>
                    <ModalHeader toggle={toggle} className='border-0 pb-0'>
                        <div className="card-title d-flex align-items-center mb-0">
                            <h5 className="mb-0 text-primary">{isAddMode === 'Add' ? 'Add Transportation Mode' : 'Update Transportation Mode'}</h5>
                        </div>
                    </ModalHeader>
                    <ModalBody className="">
                        <div className="row" >
                            <div className="col-12 mb-3">
                                <label htmlFor="name" className="form-label">
                                Name
                                </label>
                                <Field type="text" className="form-control" id="name" name='name' />
                                <ErrorMessage name='name' component={TextError} />
                            </div>
                            <div className="col-12 mb-3">
                                <label htmlFor="description" className="form-label">
                                Description
                                </label>
                                <Field type="description" className="form-control" id="description" name='description'
                                />
                                <ErrorMessage name='description' component={TextError} />
                            </div>
                        
                            <div className="col-12 mb-3">
                                <label htmlFor="file" className="form-label">
                                image/icon
                                </label>

                                <input id="file" name="file" type="file" onChange={(event) => {
                                    setFieldValue("file", event.currentTarget.files[0]);
                                    handleChange(event)
                                }} className="form-control" />
                                {file === undefined || file === '' ? '' : <img src={file}
                                    // alt={selectedRow?.terrainImg}
                                    className="img-thumbnail mt-2"
                                    height={150}
                                    width={150} />}

                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter className='border-0 pt-0'>
                        <button
                            type="button"
                            className="btn btn-secondary btn-sm"
                            onClick={()=>{
                                toggle(); 
                                setFile('')
                            }}
                        >
                            Close
                        </button>
                        <button type="submit" className="btn btn-primary btn-sm">
                            {isAddMode === "Add" ? `Save` : `Update`}
                        </button>
                    </ModalFooter>
                </Form>
                )}
            </Formik>
        </React.Fragment>
    );
};

export default TransporationModeForm;