import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { toast } from 'react-toastify';
import TextError from '../../helpers/FormikHelper/TextError';
import userServices from '../../services/user.services';
import { FieldSchema } from '../../schema/FieldSchema';
// import FoodSelect from '../Stays/FoodSelected';
// import FacilitiesSelect from '../Stays/FacilitiesSelected';
// import IncludeSelect from './IncludesSelected';
// import WhatToBringSelect from './WhatToBringSelect';

const PropertiesForm = ({ toggle, isAddMode, selectedRow, getData }) => {
    const [includes, setIncludes] = useState([])
    const [carry, setCarry] = useState([])
    const [expTyes, setExpTypes] = useState([])
    const [facilities, setFacilites] = useState([])

    const initialValues = {
        experienceName: '',
        experienceTypeId: ''
    }

    const savedValues = {
        fullAddress: selectedRow?.propertyLocated?.fullAddress,
        city: selectedRow?.propertyLocated?.city,
        state: selectedRow?.propertyLocated?.state,
        long: selectedRow?.propertyLocated?.long,
        lat: selectedRow?.propertyLocated?.lat,
        aboutProperty: selectedRow?.aboutProperty,
        uspOne: selectedRow?.uspOne,
        uspTwo: selectedRow?.uspTwo,
        uspThree: selectedRow?.uspThree,
        busTaxiStand: selectedRow?.distanceFrom?.busTaxiStand,
        airport: selectedRow?.distanceFrom?.airport,
        railwayStation: selectedRow?.distanceFrom?.railwayStation,
        airport: selectedRow?.distanceFrom?.airport,
        hostName: selectedRow?.distanceFrom?.hostName,
        distanceRailwayStation: selectedRow?.distanceFrom?.distanceRailwayStation,
        distanceAirport: selectedRow?.distanceFrom?.distanceAirport,
        distanceBusTaxiStand: selectedRow?.distanceFrom?.distanceBusTaxiStand,
        propertyStatus: selectedRow?.propertyStatus
    }

    const onSubmit = async (values) => {

        if (isAddMode === 'Add') {
            let result = await userServices.addPolicies(values);
            if (result.data.status === 'success') {

                toggle()
                getData()
                toast.success('Data Added successfully', { toastId: 'registration_success', autoClose: 3000 });
            } else {
                toast.error(result.data.message, { toastId: 'registration_fail', autoClose: 3000 });
            }
        } else {
            values.id = selectedRow?._id
            values.fullAddress = {
                fullAddress: values.fullAddress,
                city: values.city,
                state: values.state,
                long: values.long,
                lat: values.lat
            }
            values.distanceFrom = {
                railwayStation: values.railwayStation,
                distanceRailwayStation: values.distanceRailwayStation,
                airport: values.airport,
                distanceAirport: values.distanceAirport,
                busTaxiStand: values.busTaxiStand,
                distanceBusTaxiStand: values.distanceBusTaxiStand

            }
            const result = await userServices.updatePropertyById(values, selectedRow?._id);
            if (result.data.status === 'success') {

                toggle()
                getData()
                toast.success('Data Updated successfully', { toastId: 'update_success', autoClose: 3000 });
            } else {
                toast.error(result.data.message, { toastId: 'update_fail', autoClose: 3000 });

            }
        }
    }

    useEffect(() => {
        userServices.expIncludesList().then((result) => {
            if (result.data.status === 'success') {
                setIncludes(result.data.data)
            }
        })

        userServices.expTypeList().then((result) => {
            if (result.data.status === 'success') {
                setExpTypes(result.data.data)
            }
        })

        userServices.carryList().then((result) => {
            if (result.data.status === 'success') {
                setCarry(result.data.data)
            }
        })
    }, [])

    return (
        <React.Fragment>
            <Formik
                initialValues={isAddMode === 'Add' ? initialValues : savedValues}
                // validationSchema={FieldSchema(`${isAddMode === 'Add' ? 'ADD_EVENTS' : 'ADD_EVENTS'}`)}
                onSubmit={onSubmit}
                enableReinitialize
            >
                {({ setFieldValue, values }) => (<Form>
                    <ModalHeader toggle={toggle} className='border-0 pb-0'>
                        <div className="card-title d-flex align-items-center mb-0">
                            <h5 className="mb-0 text-primary">{isAddMode === 'Add' ? 'Add Properties' : 'Update Properties'}</h5>
                        </div>
                    </ModalHeader>
                    <ModalBody className="">
                        <div className="row" >
                            <div className="col-12 mb-3">
                                <label htmlFor="fullAddress" className="form-label">
                                    Address
                                </label>
                                <Field type="text" className="form-control" id="rule" name='fullAddress' />
                                <ErrorMessage name='fullAddress' component={TextError} />
                            </div>
                            <div className="col-12 mb-3">
                                <label htmlFor="city" className="form-label">
                                    City
                                </label>
                                <Field type="text" className="form-control" id="rule" name='city' />
                                <ErrorMessage name='city' component={TextError} />
                            </div>
                            <div className="col-12 mb-3">
                                <label htmlFor="state" className="form-label">
                                    State
                                </label>
                                <Field type="text" className="form-control" id="rule" name='state' />
                                <ErrorMessage name='state' component={TextError} />
                            </div>
                            <div className="col-12 mb-3">
                                <label htmlFor="lat" className="form-label">
                                    lat
                                </label>
                                <Field type="text" className="form-control" id="rule" name='lat' />
                                <ErrorMessage name='lat' component={TextError} />
                            </div>
                            <div className="col-12 mb-3">
                                <label htmlFor="long" className="form-label">
                                    long
                                </label>
                                <Field type="text" className="form-control" id="rule" name='long' />
                                <ErrorMessage name='long' component={TextError} />
                            </div>
                            <div className="col-12 mb-3">
                                <label htmlFor="aboutProperty" className="form-label">
                                    About Property
                                </label>
                                <Field type="textbox" className="form-control" id="rule" name='aboutProperty' />
                                <ErrorMessage name='aboutProperty' component={TextError} />
                            </div>
                            <div className="col-12 mb-3">
                                <label htmlFor="uspOne" className="form-label">
                                    UPS-One
                                </label>
                                <Field type="text" className="form-control" id="rule" name='uspOne' />
                                <ErrorMessage name='uspOne' component={TextError} />
                            </div>
                            <div className="col-12 mb-3">
                                <label htmlFor="uspTwo" className="form-label">
                                    USP-Two
                                </label>
                                <Field type="text" className="form-control" id="rule" name='uspTwo' />
                                <ErrorMessage name='uspTwo' component={TextError} />
                            </div>
                            <div className="col-12 mb-3">
                                <label htmlFor="uspThree" className="form-label">
                                    USP-Three
                                </label>
                                <Field type="text" className="form-control" id="rule" name='uspThree' />
                                <ErrorMessage name='uspThree' component={TextError} />
                            </div>



                            <div className="col-6 mb-3">
                                <label htmlFor="hostName" className="form-label">
                                    Host
                                </label>
                                <Field type="text" className="form-control" id="rule" name='hostName' disabled />
                                <ErrorMessage name='hostName' component={TextError} />
                            </div>


                            <div className="col-6 mb-3">
                                <label htmlFor="busTaxiStand" className="form-label">
                                    Bus/Taxi Station
                                </label>
                                <Field type="text" className="form-control" id="rule" name='busTaxiStand' />
                                <ErrorMessage name='busTaxiStand' component={TextError} />
                            </div>
                            <div className="col-6 mb-3">
                                <label htmlFor="railwayStation" className="form-label">
                                    Railway Station
                                </label>
                                <Field type="text" className="form-control" id="rule" name='railwayStation' />
                                <ErrorMessage name='railwayStation' component={TextError} />
                            </div>
                            <div className="col-6 mb-3">
                                <label htmlFor="airport" className="form-label">
                                    Airport
                                </label>
                                <Field type="text" className="form-control" id="rule" name='airport' />
                                <ErrorMessage name='airport' component={TextError} />
                            </div>
                            <div className="col-6 mb-3">
                                <label htmlFor="distanceBusTaxiStand" className="form-label">
                                    Distance Bus/Taxi Station
                                </label>
                                <Field type="text" className="form-control" id="rule" name='distanceBusTaxiStand' />
                                <ErrorMessage name='distanceBusTaxiStand' component={TextError} />
                            </div>
                            <div className="col-6 mb-3">
                                <label htmlFor="distanceRailwayStation" className="form-label">
                                    Distance Railway Station
                                </label>
                                <Field type="text" className="form-control" id="rule" name='distanceRailwayStation' />
                                <ErrorMessage name='distanceRailwayStation' component={TextError} />
                            </div>
                            <div className="col-6 mb-3">
                                <label htmlFor="distanceAirport" className="form-label">
                                    Distance Airport
                                </label>
                                <Field type="text" className="form-control" id="rule" name='distanceAirport' />
                                <ErrorMessage name='distanceAirport' component={TextError} />
                            </div>
                            {/* 
                            <IncludeSelect includes={includes} />
                            <WhatToBringSelect carry={carry} /> */}
                            <div className="col-md-6 mb-3">
                                <label htmlFor="propertyStatus" className="form-label">
                                    Status
                                </label>
                                <Field as='select' className='form-select' id="propertyStatus" name="propertyStatus" >
                                    <option value={0}>Draft</option>
                                    <option value={1}>Published</option>
                                    <option value={1}>Closed</option>

                                </Field>
                                <ErrorMessage name="propertyStatus" component={TextError} />
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter className='border-0 pt-0'>
                        <button
                            type="button"
                            className="btn btn-secondary btn-sm"
                            onClick={() => {
                                toggle();
                            }}
                        >
                            Close
                        </button>
                        <button type="submit" className="btn btn-primary btn-sm">
                            {isAddMode === "Add" ? `Save` : `Update`}
                        </button>
                    </ModalFooter>
                </Form>
                )}
            </Formik>
        </React.Fragment>
    );
};

export default PropertiesForm;