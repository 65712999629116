import React from "react";
import { Modal, ModalBody, ModalFooter } from "reactstrap";

const ConfirmationModal = ({toggle, modal, onClick, title, text, iconType}) => {

  return (
    <>
      <Modal isOpen={modal} toggle={toggle} size="md" centered>
        <ModalBody className="text-center rounded-top">
          <i className={`mdi ${iconType} text-danger big-icon`} />                                        
          <p className="h3 mt-4">{title}</p>
          <p className="h6 mb-4">{text}</p>
        </ModalBody>
        <ModalFooter className="border-0 justify-content-center pt-0 pb-4">
          <button
            type="button"
            className="btn btn-msg"
            onClick={onClick}
          >
            Yes
          </button>
          <button
            type="button"
            className="btn btn-no"
            onClick={() => {
              toggle();
            }}
          >
            No
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ConfirmationModal;
