import React from 'react';
import { Field, ErrorMessage, useFormikContext } from 'formik';

const WhatToBringSelect = ({ carry }) => {
  const { setFieldValue, values } = useFormikContext();

  const handleSelectChange = (event) => {
    const selectedValues = Array.from(event.target.selectedOptions, (option) => option.value);
    setFieldValue('carry', selectedValues); // Correctly update Formik's state with selected values
  };

  return (
    <div className="col-6 mb-3">
      <label htmlFor="carry" className="form-label">
      BRING What To Bring
      </label>
      <Field
        as="select"
        className="form-select"
        id="carry"
        name="carry"
        multiple
        value={values.carry} // Bind the value to Formik's state
        onChange={handleSelectChange}
      >
        <option disabled value="">
          Select Inclusions
        </option>
        {carry.map((e, key) => (
          <option key={key} value={e._id}>
            {e.carryName}
          </option>
        ))}
      </Field>
      <ErrorMessage name="carry" component="div" className="text-danger" />
    </div>
  );
};

export default WhatToBringSelect;
